angular
    .module('app')
    .component('paso1SelectorBanamex', {
        templateUrl: 'app/components/selectorcitibanamex/paso1/paso1SelectorBanamex.component.html',
        controller: Paso1
    });

/** @ngInject */
function Paso1($stateParams, $rootScope, $state, wizardService, $cookies, $window, $location, $timeout) {
    this.rs = $rootScope;
    this.state = $state;
    this.wizardService = wizardService;
    this.cookies = $cookies;
    this.invalidEmail = false;
    this.showSpinner = false;
    this.location = $location;
    this.sp = $stateParams;
    // patterns for validates inputs text
    this.phonePattern = "\\d+";
    this.emailPattern = /^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i;
    this.window = $window;
    this.window.fbq('track', 'ViewContent');
    this.timeout = $timeout;
    // create campaign_params json for save it in the cookies for linktrust pixels
    this.campaign_params = {
        t_num: this.sp.t_num ? this.sp.t_num : "",
        t_email: this.sp.t_email ? this.sp.t_email : "",
        utm_source: this.validate_utm_source(this.sp.utm_source) ? this.sp.utm_source : '418761',
        sub_id: this.generate_sub_id(this.sp.utm_source, this.sp.sub_id),
        SID: this.sp.SID ? this.sp.SID : "",
        utm_medium: this.sp.utm_medium ? this.sp.utm_medium : "",
        utm_campaign: this.sp.utm_campaign ? this.sp.utm_campaign : "",
        utm_term: this.sp.utm_term ? this.sp.utm_term : "",
        utm_content: this.sp.utm_content ? this.sp.utm_content : "",
        utm_nooverride: this.sp.utm_nooverride ? this.sp.utm_nooverride : "",
        origin: this.sp.origin ? this.sp.origin : "",
        email_delivery_id: this.sp.email_delivery_id ? this.sp.email_delivery_id  : "",
        email_commission_campaign: this.sp.email_commission_campaign ? this.sp.email_commission_campaign : "",
        affRefCidOrigin: this.sp.affRefCidOrigin ? this.sp.affRefCidOrigin : "",
        gclid: this.sp.gclid ? this.sp.gclid : "",
        AffiliateReferenceId: this.generate_sub_id(this.sp.utm_source, this.sp.sub_id),
    }
    this.params_extend = {
        t_num: this.sp.t_num ? this.sp.t_num : "",
        t_id: this.sp.t_id ? this.sp.t_id : "",
        t_email: this.sp.t_email ? this.sp.t_email : "",
        origin: this.sp.origin ? this.sp.origin : "",
        email_delivery_id: this.campaign_params.email_delivery_id ? this.campaign_params.email_delivery_id  : "",
        email_commission_campaign: this.campaign_params.email_commission_campaign ? this.campaign_params.email_commission_campaign : "",
        AffiliateReferenceId: this.campaign_params.sub_id,
        affRefCidOrigin: this.campaign_params.affRefCidOrigin ? this.campaign_params.affRefCidOrigin : ""
    }
    this.title = false;
    if(this.params_extend.t_num != '' && this.params_extend.t_email != ''){
        this.title = true;
        this.rs.lead.mobile_number = this.params_extend.t_num;
        this.rs.lead.email = this.params_extend.t_email;
    }
    
    const cp = this.campaign_params;
    const wp = _.find(_.values(cp), function(o){ return (o != '' && o != '418761')});
    this.rs.withParams = wp === undefined ? false : true;
    this.rs.params_extend = this.params_extend;

    // remove the campaign parameters if it is in cookies
    if (!this.cookies.get('campaign_params'))
    this.cookies.remove('campaign_params');
    // set a new cookie
    this.cookies.put('campaign_params', JSON.stringify(this.campaign_params), { secure: false, samesite: 'strict'});
    if (!this.cookies.entry_url)
    this.cookies.put('entry_url', this.window.location.href, { secure: false, samesite: 'strict'});
    // get device details
    this.rs.lead.device_details = JSON.stringify(getDetails());
    this.rs.lead.user_agent = getDetails()['Full User Agent'];
    this.rs.lead.window_size = getDetails()['Screen Size'];
    this.rs.lead.is_mobile = getDetails()['Mobile'];
    this.rs.lead.entry_url = this.cookies.get('entry_url');
    const queryString = window.location.search;
    this.rs.queryString = queryString;

    var self = this;
    self.wizardService.getIpClient().then(function(clientData) {
        // set client ip
        self.rs.lead.user_ip = clientData.data.ip;
    })
}

Paso1.prototype = {
    // this function restrict only numbers in input text
    onlyNumers: function(event) {
        const pattern = /[0-9\+\-\ ]/;
        var inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    },

    changeToPaso2: function() {
        // redefine this context
        var self = this;
        self.rs.lead.has_credit = true;
        self.rs.lead.good_payer = false;
        // validate if the lead has already sent a request to validate mail
        if (self.rs.lead.id) {
            self.state.go('selectorBanamexPaso1');
        } else {

            this.campaign_params = JSON.parse(this.cookies.get('campaign_params'));

            // add utm_source and SID to lead main object
            this.rs.lead.utm_source = this.campaign_params.utm_source;
            this.rs.lead.sid = this.campaign_params.SID;
            this.rs.lead.sub_id = this.campaign_params.SUB_ID;
            this.rs.force = this.campaign_params.force;

            // show ajax spinner
            self.showSpinner = true;
            self.rs.lead.application_path = 'TOP_TARJETAS_BANAMEX';
            self.rs.lead.loan = 0;
            self.rs.lead.return_days = 80;
            self.wizardService.createFullLead(self.rs.lead).then(function(createdLead) {
                // save lead id to update it later 
                self.rs.lead.id = createdLead.id;
                // var absUrl = self.location.absUrl();
                // self.window.gtag_report_conversion(absUrl)
                if (createdLead.duplicated) {
                    self.rs.duplicated = true;
                    // show duplicated pixel
                    self.trackPixel = "https://ojo7.ltroute.com/pixel.track?CID=437380&stepId=5180&MerchantReferenceID=" +
                        self.rs.lead.mobile_number + "_" + self.rs.lead.email + "_track";
                } else {
                    // show normal pixel
                    self.trackPixel = "https://ojo7.ltroute.com/pixel.track?CID=437380&stepId=5465&MerchantReferenceID=" +
                        self.rs.lead.mobile_number + "_" + self.rs.lead.email + "_track";
                };
                self.timeout(function(){ self.state.go('selectorBanamexPaso2'); }, 1000)   
            })
        }
    },
    isInteger: function (data) {
        try {
            if (typeof data === 'number') {
            var remainder = data % 1
            if (remainder === 0) {
                return true
            }
            }
            return false
        } catch (err) {
            return false
        }
    },

    validate_utm_source: function (utm_source) {
        // validates if utm_source is integer
        if (!this.isInteger(utm_source * 1)
            || utm_source * 1 < 418761) {
            // set utm_source for organic origin (does not belong to a valid campaign )
            return false;
        }
        return true;
    },

    generate_sub_id: function (utm_source, sub_id) {
        // if utm_source isn't valid and sub_id is empty the sub_id becomes
        // in the original utm_source
        if (!this.validate_utm_source(utm_source)) {
            // utm_source no valid, campaign no valid
            if (sub_id == '' || typeof sub_id === 'undefined') {
            // if sub_id empty or undefined return utm_source
            if (utm_source == '' || typeof utm_source === 'undefined') {
                return '';
            }
            return utm_source;
            } else {
            // if sub_id is full return sub_id
            return sub_id;
            }
        } else {
            // utm_source valid
            if (sub_id == '' || typeof sub_id === 'undefined') {
            // if sub_id empty or undefined return empty string
            return "";
            } else {
            // if sub_id is full return sub_id
            return sub_id;
            }
        }
    }
};