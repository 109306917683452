angular
    .module('app')
    .component('paso4SelectorBanamex', {
        templateUrl: 'app/components/selectorcitibanamex/paso4/paso4SelectorBanamex.component.html',
        controller: Paso4
    });

/** @ngInject */
function Paso4($rootScope, wizardService, $state, $window, $scope) {
    this.rs = $rootScope;
    this.message = '';
    this.state = $state;
    this.showSpinner = false;
    this.wizardService = wizardService;
    this.window = $window;
    this.day = '';
    this.month = '';
    this.year = '';
    if (!this.rs.lead.id || this.rs.lead.status === 'COMPLETE') {
        this.state.go('selectorBanamexPaso1');
    }
    this.window.fbq('track', 'ViewContent');
    let currentDate = new Date();
    let years = [];
    let minYear = currentDate.getFullYear() - 70; 
    this.mYear = minYear;
    for (let i = 2003; i >= minYear; i--) {
        years.push(i);
    }
    $scope.years = years;
}

Paso4.prototype = {
    validateInfo: function() {
        var self = this;

        if (self.message) {
            self.message = '';
        }
        // validate all wizard fields
        if (self.rs.lead.email == undefined ||
            self.rs.lead.email == '' ||
            self.rs.lead.mobile_number == undefined ||
            self.rs.lead.mobile_number == '' ||
            self.rs.lead.first_name == undefined ||
            self.rs.lead.first_name == '' ||
            self.year == '') {
            self.message = ' Favor de llenar la informaci&oacute;n solicitada anteriormente<br>'
            return;
        } else {
            self.rs.lead.birthdate = new Date(self.year, 0, 1);
            self.showSpinner = true;
            
            self.rs.lead.createdAt = new Date();
            self.rs.lead.updatedAt = new Date();

            const names = self.rs.lead.first_name.split(" ");
            self.rs.lead.first_name = names.length > 3 ? `${names[0].replace(/\d+/, "")} ${names[1].replace(/\d+/, "")}` : names[0].replace(/\d+/, "");
            self.rs.lead.last_name = names.length > 3 ? names[2].replace(/\d+/, "") : names.length < 2 ? "" : names[1].replace(/\d+/, "") ;
            self.rs.lead.second_last_name = names.length > 3 ? names[3].replace(/\d+/, "") : names.length == 3 ? names[2].replace(/\d+/, "") : names.length == 2 ? names[1].replace(/\d+/, "") : "";
            self.rs.lead.first_name = self.rs.lead.first_name.replace(/,/g, "");
            self.rs.lead.last_name = self.rs.lead.last_name.replace(/,/g, "");
            self.rs.lead.second_last_name = self.rs.lead.second_last_name.replace(/,/g, "");
            self.rs.lead.status = 'COMPLETE';
            self.rs.lead.accept_terms = true;
            // update shortLead to fullLead
            self.wizardService.updateLead(self.rs.lead)
            .then(function(data) {
                self.wizardService.leadApplyBanamex(self.rs.lead)
                    .then(function(resp) {
                        if (resp.status == "OK") {
                            console.log(resp.details.lender.name);
                            if(resp.details.lender.name == 'CALL_CENTER'){
                                self.state.go('gracias');
                            }else{
                                let lenderName = '';
                                let clender = '';
                                var random = Math.floor(Math.random() * 100) + 1;
                                self.wizardService.getSetting("name=XSELL_TOPTARJETASBANAMEX")
                                .then(xsell => {
                                    const vxsell = JSON.parse(xsell.data[0].value);
                                    
                                    const deviceDetails = getDetails();
                                    if(deviceDetails.Mobile){
                                        if(deviceDetails.OS == 'iOS'){
                                            clender = _.find(vxsell.mobile.ios, (lender) => {
                                                if(random > parseInt(lender.min,10) && random <= parseInt(lender.max, 10))
                                                return lender;
                                            })
                                            lenderName = clender.opt;
                                        }else{
                                            clender = _.find(vxsell.mobile.android, (lender) => {
                                                if(random > parseInt(lender.min,10) && random <= parseInt(lender.max, 10))
                                                return lender;
                                            })
                                            lenderName = clender.opt;
                                        }
                                    }else{
                                        clender = _.find(vxsell.desktop, (lender) => {
                                            if(random > parseInt(lender.min,10) && random <= parseInt(lender.max, 10))
                                            return lender;
                                        })
                                        lenderName = clender.opt;
                                    }
                                    self.wizardService.updateLeadChild({ id: self.rs.lead.id, xsell: lenderName, click: 0})
                                    .then(upd => {
                                        self.wizardService.getProductXSell(lenderName.toLowerCase())
                                        .then(function(products){
                                            self.window.fbq('track', 'CompleteRegistration');
                                            self.state.go('result_banamex', { products: products, lender: lenderName.toLowerCase()});
                                        })
                                    });
                                })
                            }
                        }
                    })
            })
        }
    }
};