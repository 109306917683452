angular
  .module('app')
  .component('mainSection', {
    templateUrl: 'app/components/mainSection/mainSection.component.html',
    controller: MainSection
  });

/** @ngInject */
function MainSection($stateParams, $localStorage, $scope, $rootScope, $cookies, $window, wizardService, $timeout, $state) {
  // init objects
  this.sp = $stateParams;
  this.rs = $rootScope;
  this.cookies = $cookies;
  this.window = $window;
  this.wizardService = wizardService;
  this.timeout = $timeout;
  this.state = $state;
  this.redirect = false;
  this.prestamo = false;
  this.rs.lead.device_details = JSON.stringify(getDetails());
  this.rs.lead.user_agent = getDetails()['Full User Agent'];
  this.rs.lead.window_size = getDetails()['Screen Size'];
  this.rs.lead.is_mobile = getDetails()['Mobile'];
  this.rs.lead.entry_url = this.window.location.href;
  this.prestamo = true;
  $scope.$storage = $localStorage;
  if(this.cookies.get('pathname')){
    // with cookie pathname
    console.log('Cookies:: ', this.cookies.get('campaign_params'));
    this.campaign_params = JSON.parse(this.cookies.get('campaign_params'));
    this.params_extend = {
      t_num: this.campaign_params.t_num ? this.campaign_params.t_num : "",
      t_id: this.campaign_params.t_id ? this.campaign_params.t_id : "",
      t_email: this.campaign_params.t_email ? this.campaign_params.t_email : "",
      origin: this.campaign_params.origin ? this.campaign_params.origin : "",
      email_delivery_id: this.campaign_params.email_delivery_id ? this.campaign_params.email_delivery_id  : "",
      email_commission_campaign: this.campaign_params.email_commission_campaign ? this.campaign_params.email_commission_campaign : "",
      affiliateReferenceId: this.campaign_params.sub_id,
      affRefCidOrigin: this.campaign_params.affRefCidOrigin ? this.campaign_params.affRefCidOrigin : "",
      id_bridge: this.campaign_params.id_bridge ? this.campaign_params.id_bridge : "",
      gclid: this.campaign_params.gclid ? this.campaign_params.gclid : "",
      fbclid: this.campaign_params.fbclid ? this.campaign_params.fbclid : "",
      ttclid: this.campaign_params.ttclid ? this.campaign_params.ttclid : ""
    }
    const cp = this.campaign_params;
    const wp = _.find(_.values(cp), function(o){ return (o != '' && o != '418761')});
    this.rs.withParams = wp === undefined ? false : true;
    this.rs.params_extend = this.params_extend;
  }else{
    // without cookie pathname
    if(this.window.location.search != ''){
      let utm_source = '';
      let SID = '';
      if(this.sp.utm_source === undefined && this.sp.gclid){
        SID = 'gclid';
        utm_source = '425145';
      }else{
        SID = this.sp.SID
        utm_source = this.sp.utm_source
      }
      
      // create campaign_params json for save it in the cookies for linktrust pixels
      this.campaign_params = {
        utm_source: this.validate_utm_source(utm_source) ? utm_source : '418761',
        sub_id: this.generate_sub_id(this.sp.utm_source, this.sp.sub_id),
        SID: SID ? SID : "unassigned",
        utm_medium: this.sp.utm_medium ? this.sp.utm_medium : "",
        utm_campaign: this.sp.utm_campaign ? this.sp.utm_campaign : "",
        utm_term: this.sp.utm_term ? this.sp.utm_term : "",
        utm_content: this.sp.utm_content ? this.sp.utm_content : "",
        utm_nooverride: this.sp.utm_nooverride ? this.sp.utm_nooverride : "",
        click_id: this.sp.click_id ? this.sp.click_id : "",
        force: this.sp.force ? this.sp.force : "",
        origin: this.sp.origin ? this.sp.origin : "",
        email_delivery_id: this.sp.email_delivery_id ? this.sp.email_delivery_id  : "",
        email_commission_campaign: this.sp.email_commission_campaign ? this.sp.email_commission_campaign : "",
        affRefCidOrigin: this.sp.affRefCidOrigin ? this.sp.affRefCidOrigin : "",
        gclid: this.sp.gclid ? this.sp.gclid : "",
        t_num: this.sp.t_num ? this.sp.t_num : "",
        t_id: this.sp.t_id ? this.sp.t_id : "",
        t_email: this.sp.t_email ? this.sp.t_email : "",
        id_bridge: this.sp.id_bridge ? this.sp.id_bridge : "",
        gclid: this.sp.gclid ? this.sp.gclid : "",
        fbclid: this.sp.fbclid ? this.sp.fbclid : "",
        ttclid: this.sp.ttclid ? this.sp.ttclid : ""
      }
  
      this.params_extend = {
        t_num: this.sp.t_num ? this.sp.t_num : "",
        t_id: this.sp.t_id ? this.sp.t_id : "",
        t_email: this.sp.t_email ? this.sp.t_email : "",
        origin: this.sp.origin ? this.sp.origin : "",
        email_delivery_id: this.campaign_params.email_delivery_id ? this.campaign_params.email_delivery_id  : "",
        email_commission_campaign: this.campaign_params.email_commission_campaign ? this.campaign_params.email_commission_campaign : "",
        affiliateReferenceId: this.campaign_params.sub_id,
        affRefCidOrigin: this.campaign_params.affRefCidOrigin ? this.campaign_params.affRefCidOrigin : "",
        id_bridge: this.sp.id_bridge ? this.sp.id_bridge : "",
        gclid: this.sp.gclid ? this.sp.gclid : "",
        fbclid: this.sp.fbclid ? this.sp.fbclid : "",
        ttclid: this.sp.ttclid ? this.sp.ttclid : ""
      }
      const cp = this.campaign_params;
      const wp = _.find(_.values(cp), function(o){ return (o != '' && o != '418761')});
      this.rs.withParams = wp === undefined ? false : true;
      this.rs.params_extend = this.params_extend;
      
      // remove the campaign parameters if it is in cookies
      // if (!this.cookies.get('campaign_params'))
      //   this.cookies.remove('campaign_params');
  
      // set a new cookie
      this.cookies.put('campaign_params', JSON.stringify(this.campaign_params), { secure: false, samesite: 'strict'});
      if (!this.cookies.entry_url)
        this.cookies.put('entry_url', this.window.location.href, { secure: false, samesite: 'strict'});
      var self = this;
      if(this.rs.params_extend.t_num != '' && this.rs.params_extend.t_email != '' && this.campaign_params.utm_source == '477596'){
        this.redirect = true;
        self.timeout(function(){ self.clickNext() }, 1000)
      }
    }else{
      if(!this.cookies.get('campaign_params')){
        let utm_source = '';
        let SID = '';
        if(this.sp.utm_source === undefined && this.sp.gclid){
          SID = 'gclid';
          utm_source = '425145';
        }else{
          SID = this.sp.SID
          utm_source = this.sp.utm_source
        }
    
        // create campaign_params json for save it in the cookies for linktrust pixels
        this.campaign_params = {
          utm_source: this.validate_utm_source(utm_source) ? utm_source : '418761',
          sub_id: this.generate_sub_id(this.sp.utm_source, this.sp.sub_id),
          SID: SID ? SID : "unassigned",
          utm_medium: this.sp.utm_medium ? this.sp.utm_medium : "",
          utm_campaign: this.sp.utm_campaign ? this.sp.utm_campaign : "",
          utm_term: this.sp.utm_term ? this.sp.utm_term : "",
          utm_content: this.sp.utm_content ? this.sp.utm_content : "",
          utm_nooverride: this.sp.utm_nooverride ? this.sp.utm_nooverride : "",
          click_id: this.sp.click_id ? this.sp.click_id : "",
          force: this.sp.force ? this.sp.force : "",
          origin: this.sp.origin ? this.sp.origin : "",
          email_delivery_id: this.sp.email_delivery_id ? this.sp.email_delivery_id  : "",
          email_commission_campaign: this.sp.email_commission_campaign ? this.sp.email_commission_campaign : "",
          affRefCidOrigin: this.sp.affRefCidOrigin ? this.sp.affRefCidOrigin : "",
          gclid: this.sp.gclid ? this.sp.gclid : "",
          t_num: this.sp.t_num ? this.sp.t_num : "",
          t_id: this.sp.t_id ? this.sp.t_id : "",
          t_email: this.sp.t_email ? this.sp.t_email : "",
          id_bridge: this.sp.id_bridge ? this.sp.id_bridge : "",
          fbclid: this.sp.fbclid ? this.sp.fbclid : "",
          ttclid: this.sp.ttclid ? this.sp.ttclid : ""
        }
    
        this.params_extend = {
          t_num: this.sp.t_num ? this.sp.t_num : "",
          t_id: this.sp.t_id ? this.sp.t_id : "",
          t_email: this.sp.t_email ? this.sp.t_email : "",
          origin: this.sp.origin ? this.sp.origin : "",
          email_delivery_id: this.campaign_params.email_delivery_id ? this.campaign_params.email_delivery_id  : "",
          email_commission_campaign: this.campaign_params.email_commission_campaign ? this.campaign_params.email_commission_campaign : "",
          affiliateReferenceId: this.campaign_params.sub_id,
          affRefCidOrigin: this.campaign_params.affRefCidOrigin ? this.campaign_params.affRefCidOrigin : "",
          id_bridge: this.campaign_params.id_bridge ? this.campaign_params.id_bridge : "",
          gclid: this.campaign_params.gclid ? this.campaign_params.gclid : "",
          fbclid: this.campaign_params.fbclid ? this.campaign_params.fbclid : "",
          ttclid: this.campaign_params.ttclid ? this.campaign_params.ttclid : ""
        }
        const cp = this.campaign_params;
        const wp = _.find(_.values(cp), function(o){ return (o != '' && o != '418761')});
        this.rs.withParams = wp === undefined ? false : true;
        this.rs.params_extend = this.params_extend;
        
        // remove the campaign parameters if it is in cookies
        // if (!this.cookies.get('campaign_params'))
        //   this.cookies.remove('campaign_params');
    
        // set a new cookie
        this.cookies.put('campaign_params', JSON.stringify(this.campaign_params), { secure: false, samesite: 'strict'});
        if (!this.cookies.entry_url)
          this.cookies.put('entry_url', this.window.location.href, { secure: false, samesite: 'strict'});
        var self = this;
        if(this.rs.params_extend.t_num != '' && this.rs.params_extend.t_email != '' && this.campaign_params.utm_source == '477596'){
          this.redirect = true;
          self.timeout(function(){ self.clickNext() }, 1000)
        }
      }else{
        var self = this;
        this.campaign_params = JSON.parse(this.cookies.get('campaign_params'));
        this.params_extend = {
          t_num: this.campaign_params.t_num ? this.campaign_params.t_num : "",
          t_id: this.campaign_params.t_id ? this.campaign_params.t_id : "",
          t_email: this.campaign_params.t_email ? this.campaign_params.t_email : "",
          origin: this.campaign_params.origin ? this.campaign_params.origin : "",
          email_delivery_id: this.campaign_params.email_delivery_id ? this.campaign_params.email_delivery_id  : "",
          email_commission_campaign: this.campaign_params.email_commission_campaign ? this.campaign_params.email_commission_campaign : "",
          affiliateReferenceId: this.campaign_params.sub_id,
          affRefCidOrigin: this.campaign_params.affRefCidOrigin ? this.campaign_params.affRefCidOrigin : "",
          id_bridge: this.campaign_params.id_bridge ? this.campaign_params.id_bridge : "",
          gclid: this.campaign_params.gclid ? this.campaign_params.gclid : "",
          fbclid: this.campaign_params.fbclid ? this.campaign_params.fbclid : "",
          ttclid: this.campaign_params.ttclid ? this.campaign_params.ttclid : ""
        }
        const cp = this.campaign_params;
        const wp = _.find(_.values(cp), function(o){ return (o != '' && o != '418761')});
        this.rs.withParams = wp === undefined ? false : true;
        this.rs.params_extend = this.params_extend;
        if(this.rs.params_extend.t_num != '' && this.rs.params_extend.t_email != '' && this.campaign_params.utm_source == '477596'){
          this.redirect = true;
          self.timeout(function(){ self.clickNext() }, 1000)
        }
      }
    }
  }
}

MainSection.prototype = {
  clickTop: function(){
    const self = this;
    self.window.scrollTo(0, 0);
  },
  clickNext: function(){
    const self = this;
    if(self.rs.params_extend.t_num != '' && self.rs.params_extend.t_email != '' && self.campaign_params.utm_source == '477596'){
      self.rs.lead.utm_source = self.campaign_params.utm_source;
      self.rs.lead.sid = self.campaign_params.SID;
      self.rs.lead.sub_id = self.campaign_params.SUB_ID;
      self.rs.force = self.campaign_params.force;
      self.wizardService.getIpClient().then(function(clientData) {
        // set client ip
        self.rs.lead.user_ip = clientData.data.ip;
        self.rs.lead.application_path = 'TOP_TARJETAS';
        self.rs.lead.loan = 0;
        self.rs.lead.return_days = 80;
        self.rs.lead.email = self.rs.params_extend.t_email;
        self.rs.lead.mobile_number = self.rs.params_extend.t_num;
        self.wizardService.createFullLead(self.rs.lead).then(function(createdLead) {
            // save lead id to update it later 
            self.rs.lead.id = createdLead.id;
            // var absUrl = self.location.absUrl();
            // self.window.gtag_report_conversion(absUrl)
            if (createdLead.duplicated) {
                self.rs.duplicated = true;
                // show duplicated pixel
                self.trackPixel = "https://ojo7.ltroute.com/pixel.track?CID=420775&stepId=2615&MerchantReferenceID=" +
                    self.rs.lead.mobile_number + "_" + self.rs.lead.email + "_track";
            } else {
                // show normal pixel
                self.trackPixel = "https://ojo7.ltroute.com/pixel.track?CID=420775&savecookie=x&MerchantReferenceID=" +
                    self.rs.lead.mobile_number + "_" + self.rs.lead.email + "_track";
                self.window.obApi('track', 'Tarjetas Lead Unico');
            };
            self.timeout(function(){ self.state.go('step2'); }, 1000)   
        })
      });
    }else{
      self.state.go('step1', { main: true });
    }
  },
  clickNextPrestamo: function(){
    const self = this;
    const extend_params = {
      t_num: self.campaign_params.t_num ? self.campaign_params.t_num : "",
      t_email: self.campaign_params.t_email ? self.campaign_params.t_email: "",
      sub_id: self.generate_sub_id(self.campaign_params.utm_source, self.campaign_params.sub_id),
      utm_medium: self.campaign_params.utm_medium ? self.campaign_params.utm_medium : "",
      utm_campaign: self.campaign_params.utm_campaign ? self.campaign_params.utm_campaign : "",
      utm_term: self.campaign_params.utm_term ? self.campaign_params.utm_term : "",
      utm_content: self.campaign_params.utm_content ? self.campaign_params.utm_content : "",
      utm_nooverride: self.campaign_params.utm_nooverride ? self.campaign_params.utm_nooverride : "",
      origin: self.campaign_params.origin ? self.campaign_params.origin : "",
      email_delivery_id: self.campaign_params.email_delivery_id ? self.campaign_params.email_delivery_id  : "",
      email_commission_campaign: self.campaign_params.email_commission_campaign ? self.campaign_params.email_commission_campaign : "",
      affiliateReferenceId: self.campaign_params.sub_id,
      affRefCidOrigin: self.campaign_params.affRefCidOrigin ? self.campaign_params.affRefCidOrigin : "",
      id_bridge: self.campaign_params.id_bridge ? self.campaign_params.id_bridge : "",
      gclid: self.campaign_params.gclid ? self.campaign_params.gclid : "",
      fbclid: self.campaign_params.fbclid ? self.campaign_params.fbclid : "",
      ttclid: self.campaign_params.ttclid ? self.campaign_params.ttclid : ""
    };
    self.window.fbq('trackSingleCustom', '457522735264304', 'click', {
      click: 'crediclic',
    });
    self.window.fbq('trackSingleCustom', '2044348308936896', 'click', {
      click: 'crediclic',
    });
    self.window.fbq('trackSingleCustom', '2508338796109313', 'click', {
      click: 'crediclic',
    });
    // self.window.ga('send', 'event', {
    //   eventCategory: 'clic crediclic',
    //   eventAction: 'button',
    //   eventLabel: 'tt_crediclic'
    // });
    self.wizardService.getLink('413749', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, extend_params) 
    .then(function(result){
        self.window.location.href = result.detail;
    })
  },
  clickClubPremier: function(){
    const self = this;
    const extend_params = {
      t_num: self.campaign_params.t_num ? self.campaign_params.t_num : "",
      t_email: self.campaign_params.t_email ? self.campaign_params.t_email: "",
      sub_id: self.generate_sub_id(self.campaign_params.utm_source, self.campaign_params.sub_id),
      utm_medium: self.campaign_params.utm_medium ? self.campaign_params.utm_medium : "",
      utm_campaign: self.campaign_params.utm_campaign ? self.campaign_params.utm_campaign : "",
      utm_term: self.campaign_params.utm_term ? self.campaign_params.utm_term : "",
      utm_content: self.campaign_params.utm_content ? self.campaign_params.utm_content : "",
      utm_nooverride: self.campaign_params.utm_nooverride ? self.campaign_params.utm_nooverride : "",
      origin: self.campaign_params.origin ? self.campaign_params.origin : "",
      email_delivery_id: self.campaign_params.email_delivery_id ? self.campaign_params.email_delivery_id  : "",
      email_commission_campaign: self.campaign_params.email_commission_campaign ? self.campaign_params.email_commission_campaign : "",
      affiliateReferenceId: self.campaign_params.sub_id,
      affRefCidOrigin: self.campaign_params.affRefCidOrigin ? self.campaign_params.affRefCidOrigin : "",
      id_bridge: self.campaign_params.id_bridge ? self.campaign_params.id_bridge : "",
      gclid: self.campaign_params.gclid ? self.campaign_params.gclid : "",
      fbclid: self.campaign_params.fbclid ? self.campaign_params.fbclid : "",
      ttclid: self.campaign_params.ttclid ? self.campaign_params.ttclid : ""
    };
    self.wizardService.getLink('434478', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, extend_params) 
    .then(function(result){
        self.window.location.href = result.detail;
    })
  },

  isInteger: function (data) {
    try {
      if (typeof data === 'number') {
        var remainder = data % 1
        if (remainder === 0) {
          return true
        }
      }
      return false
    } catch (err) {
      return false
    }
  },

  validate_utm_source: function (utm_source) {
    // validates if utm_source is integer
    if (!this.isInteger(utm_source * 1)
      || utm_source * 1 < 418761) {
      // set utm_source for organic origin (does not belong to a valid campaign )
      return false;
    }
    return true;
  },

  generate_sub_id: function (utm_source, sub_id) {
    // if utm_source isn't valid and sub_id is empty the sub_id becomes
    // in the original utm_source
    if (!this.validate_utm_source(utm_source)) {
      // utm_source no valid, campaign no valid
      if (sub_id == '' || typeof sub_id === 'undefined') {
        // if sub_id empty or undefined return utm_source
        if (utm_source == '' || typeof utm_source === 'undefined') {
          return '';
        }
        return utm_source;
      } else {
        // if sub_id is full return sub_id
        return sub_id;
      }
    } else {
      // utm_source valid
      if (sub_id == '' || typeof sub_id === 'undefined') {
        // if sub_id empty or undefined return empty string
        return "";
      } else {
        // if sub_id is full return sub_id
        return sub_id;
      }
    }

  }

}
