const dtails = getDetails();

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// document.addEventListener('om.Api.init', function(event) {
//   console.log('INIT');
// } );

document.addEventListener('om.Form.init', function(event) {
  $("#reno-field-phone").attr('maxlength','10');
  $("#reno-field-phone").attr('minlength','10');
  $("<div id='error-num' reno-error gnfbb2hnxvt0ziiffaub-reno-error reno-error-header gnfbb2hnxvt0ziiffaub-reno-error-header style='font-size: 14px; color: rgb(170, 100, 99); margin: 0px 0px 10px; text-align: center; line-height: 18px; background-color: rgb(255, 239, 239); padding: 12px; font-weight: 600; border-radius: 3px; border: 2px solid rgb(255, 153, 153); display: none; opacity: 1;'></div>").insertBefore( "#reno-field-phone" );
  $.fn.inputPhoneFilter = function(inputPhoneFilter) {
    return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
      if (inputPhoneFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
        if(this.value.length === 10){
          $("#reno-FieldsElementButton--SH3dk8Mafx5gg8FP3ZcP").attr("disabled", false);
          $("#error-num").remove();
        }else if(this.value.length > 0){
          $("#reno-FieldsElementButton--SH3dk8Mafx5gg8FP3ZcP").attr("disabled", true);
          $('#error-num').text('El número de celular es incorrecto')
          $('#error-num').css('display','block');
        }
      } else if (this.hasOwnProperty("oldValue")) {
        $("#reno-FieldsElementButton--SH3dk8Mafx5gg8FP3ZcP").attr("disabled", true);
        $('#error-num').text('El número de celular es incorrecto')
        $('#error-num').css('display','block');
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        $("#reno-FieldsElementButton--SH3dk8Mafx5gg8FP3ZcP").attr("disabled", true);
        $('#error-num').text('El número de celular es incorrecto')
        $('#error-num').css('display','none');
        this.value = "";
      }
    });
  };
  $("#reno-field-phone").inputPhoneFilter(function(value) {
    return /^\d*$/.test(value);    // Allow digits only, using a RegExp
  });
});

document.addEventListener('om.Optin.init.submit', function(event) {
	// Grab the email address submitted by the user.
    const email = event.detail.Optin.data.fields.email;
    const phone = event.detail.Optin.data.fields.phone;
    var token = 'toptarjetas:dcOz1Mv9nQc3fE74';
    var tokenHashed = btoa(token);
    let campaign_params = JSON.parse(getCookie('campaign_params'));
    if(campaign_params === null){
        campaign_params = {}
    }

    const payload = {
        email: email,
        mobile_number: phone,
        device_details: JSON.stringify(dtails),
        entry_url: "toptarjetas_optinmonster",
        user_agent: dtails['Full User Agent'],
        window_size: dtails['Screen Size'],
        is_mobile: dtails['Mobile'],
        utm_source: campaign_params.utm_source !== undefined ? campaign_params.utm_source : "",
        sid: campaign_params.SID !== undefined ? campaign_params.SID : "",
        source: "toptarjetas",
    }
    
    $.get("https://api.ipify.org/", function( data ) {
        payload.user_ip = data;
        const url = window.location.origin === 'https://www.toptarjetas.mx' ? 'https://api7.ojo7.com/' : 'http://stg-api7.ojo7.com/';
        const isProd = window.location.origin === 'https://www.toptarjetas.mx' ? true : false;
        $.ajax({
          method: "GET",
          url: url + 'lead/v2/' + payload.email + '/' + payload.mobile_number,
          headers: {'Authorization': 'Basic ' + tokenHashed},
          data: JSON.stringify(payload),
          contentType:'application/json',
          dataType: "json",
          error: function(XMLHttpRequest, textStatus, errorThrown){
              console.log("ERROR: ", XMLHttpRequest, textStatus, errorThrown);
          },
        })
        .done(function(resp){
          let pixel = '';
          const affiliates_internos = [418761, 421382, 421384, 421388, 421390, 421398, 421493, 421574, 
            421581, 422168, 422498, 422827, 422837, 422888, 422995, 423011, 423443, 424445, 424505, 424713, 
            425060, 425074, 425145, 426251, 427441, 427652, 427652, 427793, 427794, 427795, 427796, 427828, 
            427868, 428020, 428042, 431449, 431591, 431592, 431700, 431851, 462598, 462741];
          const utm = campaign_params.utm_source;
          // const utm = 444444;
          let find = undefined;
          affiliates_internos.forEach(function(aff){
            if(aff == utm){
              find = true
            }
          })
          $.ajax({
            method: "GET",
            url: url + 'toptarjetas/v2/setting?name=AFFILIATES_EXT',
            headers: {'Authorization': 'Basic ' + tokenHashed},
            contentType:'application/json',
            dataType: "json",
            error: function(XMLHttpRequest, textStatus, errorThrown){
                console.log("ERROR: ", XMLHttpRequest, textStatus, errorThrown);
            },
          })
          .done(function(setting){
            if(find){
              if (resp.duplicated) {
                // show duplicated pixel
                pixel = "https://ojo7.ltroute.com/pixel.track?CID=420775&stepId=2615&MerchantReferenceID=" + payload.mobile_number + "_" + payload.email + "_track";
              } else {
                  // show normal pixel
                  pixel = "https://ojo7.ltroute.com/pixel.track?CID=420775&savecookie=x&MerchantReferenceID=" + payload.mobile_number + "_" + payload.email + "_track";
              };
            }else{
              if(setting.data[0].value == 'false'){
                if (resp.duplicated) {
                  // show duplicated pixel
                  pixel = "https://ojo7.ltroute.com/pixel.track?CID=420775&stepId=2615&MerchantReferenceID=" + payload.mobile_number + "_" + payload.email + "_track";
                } else {
                    // show normal pixel
                    pixel = "https://ojo7.ltroute.com/pixel.track?CID=420775&savecookie=x&MerchantReferenceID=" + payload.mobile_number + "_" + payload.email + "_track";
                };
              }else{
                if (resp.duplicated) {
                  pixel = "https://ojo7.ltroute.com/pixel.track?CID=420775&stepId=2615&MerchantReferenceID=" + payload.mobile_number + "_" + payload.email + "_track";
                }
              }
            }
            $.ajax({
              method: "POST",
              url: url + 'optinmonster/v2/lead',
              headers: {'Authorization': 'Basic ' + tokenHashed},
              data: JSON.stringify(payload),
              contentType:'application/json',
              dataType: "json",
              error: function(XMLHttpRequest, textStatus, errorThrown){
                  console.log("ERROR: ", XMLHttpRequest, textStatus, errorThrown);
              },
            })
            .done(function(resp){
                console.log("Guardado con exito");
                if(isProd){
                  $(".Element").append('<IMG src="'+pixel+'" width="1" height="1" border="0" />');
                }
            }); 
          });
        })
    });
} );