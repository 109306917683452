angular
  .module('app')
  .component('resultBanamexComponent', {
    templateUrl: 'app/components/result/resultBanamex.component.html',
    controller: ResultBanamex
  });

/** @ngInject */
function ResultBanamex($stateParams, $uibModal, $rootScope, $state, $window) {
  this.sp = $stateParams; 
  this.uibModal = $uibModal; 
  this.rs = $rootScope; 
  this.state = $state; 
  // get products from the stateParams
  this.products = this.sp.products; 
  this.lender = this.sp.lender;

  if(this.rs.params_extend.t_num == '')
    this.rs.params_extend.t_num = this.rs.lead.mobile_number
  
    if(this.rs.params_extend.t_email == '')
    this.rs.params_extend.t_email = this.rs.lead.email
  
  if(!this.rs.lead.id){ 
    this.state.go('app'); 
  } 
  this.window = $window;
  this.window.fbq('track', 'ViewContent');
  const deviceDetails = getDetails();

  this.pixelImpression = '';
  if(deviceDetails.OS == 'iOS'){
    this.pixelImpression = `https://ojo7.ltroute.com/impression.track?CID=435681&AFID=${this.rs.lead.utm_source && this.rs.lead.utm_source !== '' ? this.rs.lead.utm_source : '418761'}&SID=AD-${this.rs.lead.sid && this.rs.lead.sid !== '' ? this.rs.lead.sid : 'unassigned'}`;
  }else{
    this.pixelImpression = `https://ojo7.ltroute.com/impression.track?CID=435679&AFID=${this.rs.lead.utm_source && this.rs.lead.utm_source !== '' ? this.rs.lead.utm_source : '418761'}&SID=AD-${this.rs.lead.sid && this.rs.lead.sid !== '' ? this.rs.lead.sid : 'unassigned'}`;    
  }
} 
 
ResultBanamex.prototype = { 
 
  openModal: function (product) { 
    // open modal dialog 
    var modalInstance = this.uibModal.open({ 
      animation: true, 
      component: 'modalProductComponent', 
      windowClass: 'modalWindowClass', 
      backdropClass: 'zindex2', 
      resolve: { 
        product: function () {
          product.provider = `B${product.provider}`;
          return product; 
        } 
      } 
    }); 
 
    // handle the buttons result 
    modalInstance.result.then( 
      function ok(product) { 
         
      }, 
      function cancel() { 
 
      } 
    ); 
 
  }

};