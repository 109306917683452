angular
    .module('app')
    .component('thankyouAffiliatesComponent', {
        templateUrl: 'app/components/thankyou/thankyouaffiliates.component.html',
        controller: ThankyouAffiliatesExt
    });

/** @ngInject */
function ThankyouAffiliatesExt($rootScope, wizardService, $stateParams, $state, $window, $timeout) {
    this.rs = $rootScope;
    this.wizardService = wizardService;
    this.sp = $stateParams;
    this.state = $state;
    this.window = $window;
    this.timeout = $timeout;
    this.showSpinner = true;
    const utm_source = this.sp.utm_source;
    const id = this.sp.id;
    const SID = this.sp.SID;
    // const email = this.sp.email;
    // const phone = this.sp.phone;
    // const sid = this.sp.sid;
    var self = this;
    self.clickTrack1 = `https://ojo7.ltroute.com/click.track?CID=431456&AFID=${utm_source}&SID=TopTarjetas-${SID}`;
    self.clickTrack2 = `https://ojo7.ltroute.com/click.track?CID=430815&AFID=${utm_source}&SID=TopTarjetas-${SID}`;
    self.wizardService.getLinkCoru(id)
    .then(function(result){
        console.log(result);
        let url = 'https://toptarjetas.mx';
        if(result.status == 'OK'){
            if(result.data.length > 0){
                url = result.data[0].url;
                self.window.location.href = url;
            }else{
                self.window.location.href = url;   
            }
        }else{
            self.window.location.href = url;   
        }
        
        
    });
    // self.clickTrack2 = `https://ojo7.ltroute.com/click.track?CID=420775&AFID=${utm_source}&SID=${sid}`;
    // this.timeout(function(){ 
    //     self.conversionPixel = "https://ojo7.ltroute.com/pixel.track?CID=420775&p=img&MerchantReferenceID=" + phone + "_" + email + "_track";
    // }, 2000);

    // this.window.dataLayer.push({
    //     'event': 'Pageview_Affiliates',
    //     'pagePath': '/thankyou_affiliates',
    //     'pageTitle': 'Thank you affiliates' //some arbitrary name for the page/state
    // });

    // this.window.ga('send', 'pageview', '/thankyou_affiliates');
    // this.window.fbq('track', 'ViewContent');
}

ThankyouAffiliatesExt.prototype = {
}