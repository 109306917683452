angular
    .module('app')
    .component('contactComponent', {
        templateUrl: 'app/components/legal/contact.component.html',
        controller: Contact
    });

/** @ngInject */
function Contact(contactService, $state) {

    // init variables
    this.contactService = contactService;
    this.state = $state;

    // patterns for validates inputs text
    this.phonePattern = "\\d+";
    this.emailPattern = /^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i;
    this.showSpinner = false;
    this.error = '';
    var message = {
        name: '',
        email: '',
        phone: '',
        detail: ''
    }

}

Contact.prototype = {

    sendMessage: function () {
        var self = this;
        self.showSpinner = true;
        var formatMessage = '<p><strong>Preguntas top tarjetas contact page&nbsp;</strong></p>'
            + '<p><strong>Nombre:&nbsp;</strong> '+ self.message.name + '</p>'
            + '<p><strong>Email:&nbsp;</strong>' + self.message.email + '</p>'
            + '<p><strong>N&uacute;mero de tel&eacute;fono :&nbsp;</strong>' + self.message.phone + '</p>'
            + '<p><strong>Mensaje:&nbsp;</strong>'+ self.message.detail + '</p>';

        self.contactService.sendMessage(formatMessage).then(function (data) {
            self.showSpinner = false;
            if (data.status == 'OK') {
                self.state.go('message_ok');
            } else {
                self.error = 'Ocurrio un error inesperado al enviar su pregunta, por favor intente mas tarde.';
            }

        });
    },

    // this function restrict only numbers in input text
    onlyNumers: function (event) {
        const pattern = /[0-9\+\-\ ]/;
        var inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    },

};