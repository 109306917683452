angular
    .module('app')
    .component('thankyouComponent', {
        templateUrl: 'app/components/thankyou/thankyou.component.html',
        controller: ThankyouInfo
    });

/** @ngInject */
function ThankyouInfo($rootScope, $stateParams, $state) {
    this.rs = $rootScope;
    this.sp = $stateParams;
    this.state = $state;

    // if (!this.sp.full_app) {
    //     this.state.go('app');
    // }

    // this.conversionPixel = "https://ojo7.ltroute.com/pixel.track?CID=420723&p=img&MerchantReferenceID="
    //     + this.sp.lead_id + "_" + this.sp.lead_email;

    // clear lead object to avoid browser back action
    this.rs.lead = {
        name: '',
        lastname: '',
        email: '',
        mobile_number: '',
        has_credit: '',
        good_payer: '',
        monthly_income: '',
        interest: '',
        accept_terms: false,
        accept_privacy: false,
        device_details: ''
    }

}

ThankyouInfo.prototype = {


}