angular
  .module('app')
  .component('faqComponent', {
    templateUrl: 'app/components/faq/faq.component.html',
    controller: Faq
  });

/** @ngInject */
function Faq() {

}

Faq.prototype = {
  
};