angular
    .module('app')
    .component('modalFormFlinkInversionComponent', {
        templateUrl: 'app/components/flinkInversion/modalFormFlinkInversion/modalFormFlinkInversion.component.html',
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        },
        controller: ModalFormFlinkInversion,
    });

/** @ngInject */
function ModalFormFlinkInversion(wizardService, $cookies, $timeout, $rootScope, EnvironmentConfig, $window) {
    // init variables
    this.EnvironmentConfig = EnvironmentConfig;
    this.wizardService = wizardService;
    this.cookies = $cookies;
    this.timeout = $timeout;
    this.window = $window;
    // patterns for validates inputs text
    this.phonePattern = "\\d+";
    this.emailPattern = /^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i;
    // show email invalid message
    this.invalidEmail = false;
    // show ajax spinner
    this.showSpinner = false;
    // lead data
    this.email = "";
    this.phone = "";
    this.campaign_params = JSON.parse(this.cookies.get('campaign_params'));
    this.entry_url = this.cookies.get('entry_url');
    this.rs = $rootScope;
};


ModalFormFlinkInversion.prototype = {

    // this function executes when modal-dialog opens
    $onInit: function() {
        this.product = this.resolve.product;
    },

    // this function executes when the user clicks ok butto
    ok: function() {
        var self = this;
        var win = this.window;
        const extend_params = {
          sub_id: self.generate_sub_id(self.campaign_params.utm_source, self.campaign_params.sub_id),
          utm_medium: self.campaign_params.utm_medium ? self.campaign_params.utm_medium : "",
          utm_campaign: self.campaign_params.utm_campaign ? self.campaign_params.utm_campaign : "",
          utm_term: self.campaign_params.utm_term ? self.campaign_params.utm_term : "",
          utm_content: self.campaign_params.utm_content ? self.campaign_params.utm_content : "",
          utm_nooverride: self.campaign_params.utm_nooverride ? self.campaign_params.utm_nooverride : "",
          origin: self.campaign_params.origin ? self.campaign_params.origin : "",
          email_delivery_id: self.campaign_params.email_delivery_id ? self.campaign_params.email_delivery_id  : "",
          email_commission_campaign: self.campaign_params.email_commission_campaign ? self.campaign_params.email_commission_campaign : "",
          t_num: self.phone ? self.phone : "",
          t_email: self.email ? self.email: "",
          AffiliateReferenceId: self.generate_sub_id(self.campaign_params.utm_source, self.campaign_params.sub_id),
          affRefCidOrigin: self.campaign_params.affRefCidOrigin ? self.campaign_params.affRefCidOrigin : "",
        };
        // validate duplicated lead
        self.showSpinner = true;
        // get client ip
        self.wizardService.getIpClient().then(function(clientData) {
            // calls leadService for validate email and create the lead
            self.wizardService.createFullLeadToptarjetasFlink({
                email: self.email,
                mobile_number: self.phone,
                utm_source: self.campaign_params.utm_source,
                entry_url: self.entry_url,
                user_ip: clientData.data.ip,
                device_details: JSON.stringify(getDetails()),
                sid: self.campaign_params.SID,
                country: 'MX',
                application_path: 'TOP_TARJETAS',
                status: 'COMPLETE',
            }).then(function(data) {
                if (data.duplicated) {
                    if(extend_params.affRefCidOrigin == '435257'){
                      // step duplicated convertion
                      self.trackPixel = "https://ojo7.ltroute.com/pixel.track?CID=435257&stepId=4878&MerchantReferenceID=" +
                        data.row.id + "_" + data.row.email;
                    }else{
                      // step duplicated convertion
                      self.trackPixel = "https://ojo7.ltroute.com/pixel.track?CID=420775&stepId=2615&MerchantReferenceID=" +
                        data.row.id + "_" + data.row.email;
                    }
                    
                } else {
                  if(extend_params.affRefCidOrigin == '435257'){
                    // new convertion 
                    self.trackPixel = "https://ojo7.ltroute.com/pixel.track?CID=435257&stepId=4877&MerchantReferenceID=" +
                      data.row.id + "_" + data.row.email;
                  }else{
                    // new convertion 
                    self.trackPixel = "https://ojo7.ltroute.com/pixel.track?CID=420775&stepId=4890&savecookie=x&MerchantReferenceID=" +
                      data.row.id + "_" + data.row.email;
                  }
                    
                }
                // redirect to client landing              
                self.timeout(function() {
                    const deviceDetails = getDetails();
                    if(deviceDetails.OS == 'iOS'){
                        const cid = extend_params.affRefCidOrigin != '435257' ? '435158' :  '435229';
                        self.wizardService.getLinkFlink(cid, self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, extend_params, true)
                        .then(function(result){
                          if (data.duplicated) {
                            self.window.fbq('trackCustom', 'lead_duplicated', {
                              lender: 'flink_ios',
                              currency: 'USD',
                              value: '0.20',
                              lead: {
                                  id: self.rs.lead.id
                              }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_duplicated',
                                eventAction: 'button',
                                eventLabel: 'flink_ios'
                            });
                          }else{
                            self.window.fbq('trackCustom', 'lead_new', {
                              lender: 'flink_ios',
                              currency: 'USD',
                              value: '0.60',
                              lead: {
                                  id: self.rs.lead.id
                              }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_new',
                                eventAction: 'button',
                                eventLabel: 'flink_ios'
                            });
                          }
                          self.window.fbq('trackCustom', 'lead_general', {
                            lender: 'flink_ios',
                            currency: 'USD',
                            value: '0.40',
                            lead: {
                                id: self.rs.lead.id
                            }
                          });
                          self.window.ga('send', 'event', {
                              eventCategory: 'lead_general',
                              eventAction: 'button',
                              eventLabel: 'flink_ios'
                          });
                          // console.log(result);
                          self.window.location.href = result.detail;
                        })
                    }else{
                      const cid = extend_params.affRefCidOrigin != '435257' ? '435157' :  '435228';
                        self.wizardService.getLinkFlink(cid, self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, extend_params, true)
                        .then(function(result){
                          if (data.duplicated) {
                            self.window.fbq('trackCustom', 'lead_duplicated', {
                              lender: 'flink_android',
                              currency: 'USD',
                              value: '0.20',
                              lead: {
                                  id: self.rs.lead.id
                              }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_duplicated',
                                eventAction: 'button',
                                eventLabel: 'flink_android'
                            });
                          }else{
                            self.window.fbq('trackCustom', 'lead_new', {
                              lender: 'flink_android',
                              currency: 'USD',
                              value: '0.60',
                              lead: {
                                  id: self.rs.lead.id
                              }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_new',
                                eventAction: 'button',
                                eventLabel: 'flink_android'
                            });
                          }
                          self.window.fbq('trackCustom', 'lead_general', {
                              lender: 'flink_android',
                              currency: 'USD',
                              value: '0.40',
                              lead: {
                                  id: self.rs.lead.id
                              }
                          });
                          self.window.ga('send', 'event', {
                              eventCategory: 'lead_general',
                              eventAction: 'button',
                              eventLabel: 'flink_android'
                          }); 
                          self.window.location.href = result.detail;
                          // console.log(result);
                        })
                    }
                }, 1000);
            });
      });

    },

    // this function executes when the user clicks cancel button
    cancel: function() {
        this.dismiss({ $value: 'cancel' });
    },

    // this function restrict only numbers in input text
    onlyNumers: function(event) {
        const pattern = /[0-9\+\-\ ]/;
        var inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    },
    isInteger: function (data) {
        try {
          if (typeof data === 'number') {
            var remainder = data % 1
            if (remainder === 0) {
              return true
            }
          }
          return false
        } catch (err) {
          return false
        }
      },
    validate_utm_source: function (utm_source) {
        // validates if utm_source is integer
        if (!this.isInteger(utm_source * 1)
          || utm_source * 1 < 418761) {
          // set utm_source for organic origin (does not belong to a valid campaign )
          return false;
        }
        return true;
      },
    
      generate_sub_id: function (utm_source, sub_id) {
        // if utm_source isn't valid and sub_id is empty the sub_id becomes
        // in the original utm_source
        if (!this.validate_utm_source(utm_source)) {
          // utm_source no valid, campaign no valid
          if (sub_id == '' || typeof sub_id === 'undefined') {
            // if sub_id empty or undefined return utm_source
            if (utm_source == '' || typeof utm_source === 'undefined') {
              return '';
            }
            return utm_source;
          } else {
            // if sub_id is full return sub_id
            return sub_id;
          }
        } else {
          // utm_source valid
          if (sub_id == '' || typeof sub_id === 'undefined') {
            // if sub_id empty or undefined return empty string
            return "";
          } else {
            // if sub_id is full return sub_id
            return sub_id;
          }
        }
    
      }
};