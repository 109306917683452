angular
    .module('app')
    .component('modalProductComponent', {
        templateUrl: 'app/components/modalProduct/modalProduct.component.html',
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        },
        controller: ModalProduct,
    });

/** @ngInject */
function ModalProduct($window, $rootScope, wizardService, $state, $cookies) {
    // init variables
    this.window = $window;
    this.rs = $rootScope;
    this.wizardService = wizardService;
    this.state = $state;
    this.cookies = $cookies;
    this.showSpinner = false;
    this.campaign_params = JSON.parse(this.cookies.get('campaign_params'));
};


ModalProduct.prototype = {

    // this function executes when modal-dialog opens
    $onInit: function () {
        this.product = this.resolve.product;
    },

    getLink: function(product, afid) {
        var self = this;
        var url = vsprintf(product.pixel_url, [
            afid,
            self.campaign_params.utm_source,
            self.campaign_params.SID ? 'TopTarjetas-' + self.campaign_params.SID : 'TopTarjetas-'
        ]);
        let arrayParams = _.remove(_.map(self.rs.params_extend, function(value, key){
            if(value != ''){
                return `${key}=${value}`
            }
        }), function(o) { return o !== undefined });
        let params = '';
        if(arrayParams.length > 0){
            params = _.join(arrayParams, '&');
        }
        url = params != '' ? url + '&' + params : url;
        return url;
    },

    // this function executes when the user clicks ok butto
    ok: function (product) {
        var self = this;
        self.showSpinner = true;
        var utm_source_ojo7 = [ 422827, 422168, 418761, 425145, 421382, 424505, 427794, 427795, 425060, 427652, 421390, 423011, 423443, 427868, 427796, 422498, 427793, 427828, 421388, 421581, 425074, 424713, 426251 ]
        const utm = self.campaign_params.utm_source;
        const deviceDetails = getDetails();
        self.rs.lead.xsell = product.provider;
        self.rs.lead.click = 1;
        switch (product.provider) {
            case 'AMEX':
                self.window.obApi('track', 'Tarjetas Click en Amex');
                const find = _.find(utm_source_ojo7, function(value) { return value == utm; });
                switch (product.friendly_name) {
                    case 'goldCard':
                            this.window.ga('send', 'event', 'amex', 'click', 'Gold Card');
                        if (utm == 424881){ //PINO
                            // var url = vsprintf(product.pixel_url, [
                            //     2334546,
                            //     self.campaign_params.utm_source,
                            //     self.campaign_params.SID ? 'TopTarjetas-' + self.campaign_params.SID : 'TopTarjetas-'
                            // ]);
                            var url = this.getLink(product, 2334546);
                            self.window.location.href = url;
                        }else if(utm == 422168){ // MAIL
                            // var url = vsprintf(product.pixel_url, [
                            //     2334543,
                            //     self.campaign_params.utm_source,
                            //     self.campaign_params.SID ? 'TopTarjetas-' + self.campaign_params.SID : 'TopTarjetas-'
                            // ]);
                            var url = this.getLink(product, 2334543);
                            self.window.location.href = url;
                        }else if(find == undefined){ // AFILIADOS EXTERNOS
                            // var url = vsprintf(product.pixel_url, [
                            //     2334545,
                            //     self.campaign_params.utm_source,
                            //     self.campaign_params.SID ? 'TopTarjetas-' + self.campaign_params.SID : 'TopTarjetas-'
                            // ]);
                            var url = this.getLink(product, 2334545);
                            self.window.location.href = url;
                        }else{ // AFFILIADOS INTERNOS OJO7
                            // var url = vsprintf(product.pixel_url, [
                            //     2334544,
                            //     self.campaign_params.utm_source,
                            //     self.campaign_params.SID ? 'TopTarjetas-' + self.campaign_params.SID : 'TopTarjetas-'
                            // ]);
                            var url = this.getLink(product, 2334544);
                            self.window.location.href = url;
                        }
                        break;
                    case 'eliteCard':
                            if(self.rs.duplicated){
                                self.window.fbq('trackCustom', 'lead_duplicated', {
                                    lender: 'amex',
                                    currency: 'USD',
                                    value: '0.20',
                                    lead: {
                                        id: self.rs.lead.id
                                    }
                                });
                                self.window.ga('send', 'event', {
                                    eventCategory: 'lead_duplicated',
                                    eventAction: 'button',
                                    eventLabel: 'amex'
                                });
                            }else{
                                self.window.fbq('trackCustom', 'lead_new', {
                                    lender: 'amex',
                                    currency: 'USD',
                                    value: '0.60',
                                    lead: {
                                        id: self.rs.lead.id
                                    }
                                });
                                self.window.ga('send', 'event', {
                                    eventCategory: 'lead_new',
                                    eventAction: 'button',
                                    eventLabel: 'amex'
                                });
                            }
                            self.window.fbq('trackCustom', 'lead_general', {
                                lender: 'amex',
                                currency: 'USD',
                                value: '0.40',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_general',
                                eventAction: 'button',
                                eventLabel: 'amex'
                            });
                            self.window.ga('send', 'event', 'amex', 'click', 'EliteCard');
                            self.wizardService.getLink('426068', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                            .then(function(result){
                                self.window.location.href = result.detail;
                            })
                        break;
                    case 'greenCard':
                            this.window.ga('send', 'event', 'amex', 'click', 'GreenCard')
                            if (utm == 424881){ //PINO
                                // var url = vsprintf(product.pixel_url, [
                                //     2325878,
                                //     self.campaign_params.utm_source,
                                //     self.campaign_params.SID ? 'TopTarjetas-' + self.campaign_params.SID : 'TopTarjetas-'
                                // ]);
                                var url = this.getLink(product, 2325878);
                                self.window.location.href = url;
                            }else if(utm === 422168){ // MAIL
                                // var url = vsprintf(product.pixel_url, [
                                //     2325793,
                                //     self.campaign_params.utm_source,
                                //     self.campaign_params.SID ? 'TopTarjetas-' + self.campaign_params.SID : 'TopTarjetas-'
                                // ]);
                                var url = this.getLink(product, 2325793);
                                self.window.location.href = url;
                            }else if(find == undefined){ // AFILIADOS EXTERNOS
                                // var url = vsprintf(product.pixel_url, [
                                //     2325875,
                                //     self.campaign_params.utm_source,
                                //     self.campaign_params.SID ? 'TopTarjetas-' + self.campaign_params.SID : 'TopTarjetas-'
                                // ]);
                                var url = this.getLink(product, 2325875);
                                self.window.location.href = url;
                            }else{ // AFFILIADOS INTERNOS OJO7
                                // var url = vsprintf(product.pixel_url, [
                                //     2325797,
                                //     self.campaign_params.utm_source,
                                //     self.campaign_params.SID ? 'TopTarjetas-' + self.campaign_params.SID : 'TopTarjetas-'
                                // ]);
                                var url = this.getLink(product, 2325875);
                                self.window.location.href = url;
                            }
                        break;
                    case 'platinumCard':
                        self.window.ga('send', 'event', 'amex', 'click', 'Platinum Card');
                        if (utm == 424881){ //PINO
                            // var url = vsprintf(product.pixel_url, [
                            //     2325879,
                            //     self.campaign_params.utm_source,
                            //     self.campaign_params.SID ? 'TopTarjetas-' + self.campaign_params.SID : 'TopTarjetas-'
                            // ]);
                            var url = this.getLink(product, 2325879);
                            self.window.location.href = url;
                        }else if(utm == 422168){ // MAIL
                            // var url = vsprintf(product.pixel_url, [
                            //     2325794,
                            //     self.campaign_params.utm_source,
                            //     self.campaign_params.SID ? 'TopTarjetas-' + self.campaign_params.SID : 'TopTarjetas-'
                            // ]);
                            var url = this.getLink(product, 2325794);
                            self.window.location.href = url;
                        }else if(find == undefined){ // AFILIADOS EXTERNOS
                            // var url = vsprintf(product.pixel_url, [
                            //     2325876,
                            //     self.campaign_params.utm_source,
                            //     self.campaign_params.SID ? 'TopTarjetas-' + self.campaign_params.SID : 'TopTarjetas-'
                            // ]);
                            var url = this.getLink(product, 2325876);
                            self.window.location.href = url;
                        }else{ // AFFILIADOS INTERNOS OJO7
                            // var url = vsprintf(product.pixel_url, [
                            //     2325796,
                            //     self.campaign_params.utm_source,
                            //     self.campaign_params.SID ? 'TopTarjetas-' + self.campaign_params.SID : 'TopTarjetas-'
                            // ]);
                            var url = this.getLink(product, 2325796);
                            self.window.location.href = url;
                        }
                        break;
                }
                break;
            case 'AZTECA':
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    if(deviceDetails.OS == 'iOS'){
                        if(self.rs.duplicated){
                            self.window.fbq('trackSingleCustom','2508338796109313', 'lead_duplicated', {
                                lender: 'azteca_ios',
                                currency: 'USD',
                                value: '0.20',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.fbq('trackSingleCustom','2257303141245847', 'lead_duplicated', {
                                lender: 'azteca_ios',
                                currency: 'USD',
                                value: '0.20',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_duplicated',
                                eventAction: 'button',
                                eventLabel: 'azteca_ios'
                            });
                        }else{
                            self.window.fbq('trackSingleCustom','2508338796109313', 'lead_new', {
                                lender: 'azteca_ios',
                                currency: 'USD',
                                value: '0.60',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.fbq('trackSingleCustom','2257303141245847', 'lead_new', {
                                lender: 'azteca_ios',
                                currency: 'USD',
                                value: '0.60',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_new',
                                eventAction: 'button',
                                eventLabel: 'azteca_ios'
                            });
                        }
                        self.window.fbq('trackSingleCustom', '2508338796109313','lead_general', {
                            lender: 'azteca_ios',
                            currency: 'USD',
                            value: '0.40',
                            lead: {
                                id: self.rs.lead.id
                            }
                        });
                        self.window.fbq('trackSingleCustom', '2257303141245847','lead_general', {
                            lender: 'azteca_ios',
                            currency: 'USD',
                            value: '0.40',
                            lead: {
                                id: self.rs.lead.id
                            }
                        });
                        self.window.ga('send', 'event', {
                            eventCategory: 'lead_general',
                            eventAction: 'button',
                            eventLabel: 'azteca_ios'
                        });
                        self.wizardService.getLink('430147', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                        .then(function(result){
                            self.window.location.href = result.detail;
                        })
                    }else{
                        if(self.rs.duplicated){
                            self.window.fbq('trackSinleCustom','2508338796109313', 'lead_duplicated', {
                                lender: 'azteca_android',
                                currency: 'USD',
                                value: '0.20',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.fbq('trackSinleCustom','2257303141245847', 'lead_duplicated', {
                                lender: 'azteca_android',
                                currency: 'USD',
                                value: '0.20',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_duplicated',
                                eventAction: 'button',
                                eventLabel: 'azteca_android'
                            });
                        }else{
                            self.window.fbq('trackSingleCustom','2508338796109313', 'lead_new', {
                                lender: 'azteca_android',
                                currency: 'USD',
                                value: '0.60',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.fbq('trackSingleCustom','2257303141245847', 'lead_new', {
                                lender: 'azteca_android',
                                currency: 'USD',
                                value: '0.60',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_new',
                                eventAction: 'button',
                                eventLabel: 'azteca_android'
                            });
                        }
                        self.window.fbq('trackSingleCustom', '2508338796109313','lead_general', {
                            lender: 'azteca_android',
                            currency: 'USD',
                            value: '0.40',
                            lead: {
                                id: self.rs.lead.id
                            }
                        });
                        self.window.fbq('trackSingleCustom', '2257303141245847','lead_general', {
                            lender: 'azteca_android',
                            currency: 'USD',
                            value: '0.40',
                            lead: {
                                id: self.rs.lead.id
                            }
                        });
                        self.window.ga('send', 'event', {
                            eventCategory: 'lead_general',
                            eventAction: 'button',
                            eventLabel: 'azteca_android'
                        });
                        self.wizardService.getLink('430148', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                        .then(function(result){
                            self.window.location.href = result.detail;
                        })
                    }
                })
                break;
            case 'KUESKI':
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.window.obApi('track', 'Tarjetas Click en Lender UTM');
                    self.wizardService.getLink('402954', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                
                break; 
            case 'KUESKI_PINO':
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.window.obApi('track', 'Tarjetas Click en Lender UTM');
                    self.wizardService.getLink('424712', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break; 
            case 'DINERIA':
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('436836', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'CORU':
                self.wizardService.getLink('431456', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                .then(function(result){
                    self.window.location.href = result.detail;
                })
                break; 
            case 'KLAR':
                self.wizardService.getLink('430133', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                .then(function(result){
                    self.window.location.href = result.detail;
                })
                break;
            case 'FLINK':
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    if(deviceDetails.OS == 'iOS'){
                        if(self.rs.duplicated){
                            self.window.fbq('trackSingleCustom', '2508338796109313','lead_duplicated', {
                                lender: 'flink_ios',
                                currency: 'USD',
                                value: '0.20',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.fbq('trackSingleCustom', '2257303141245847','lead_duplicated', {
                                lender: 'flink_ios',
                                currency: 'USD',
                                value: '0.20',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_duplicated',
                                eventAction: 'button',
                                eventLabel: 'flink_ios'
                            });
                        }else{
                            self.window.fbq('trackSingleCustom','2508338796109313', 'lead_new', {
                                lender: 'flink_ios',
                                currency: 'USD',
                                value: '0.60',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.fbq('trackSingleCustom','2257303141245847', 'lead_new', {
                                lender: 'flink_ios',
                                currency: 'USD',
                                value: '0.60',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_new',
                                eventAction: 'button',
                                eventLabel: 'flink_ios'
                            });
                        }
                        self.window.fbq('trackSingleCustom','2508338796109313', 'lead_general', {
                            lender: 'flink_ios',
                            currency: 'USD',
                            value: '0.40',
                            lead: {
                                id: self.rs.lead.id
                            }
                        });
                        self.window.fbq('trackSingleCustom','2257303141245847', 'lead_general', {
                            lender: 'flink_ios',
                            currency: 'USD',
                            value: '0.40',
                            lead: {
                                id: self.rs.lead.id
                            }
                        });
                        self.window.ga('send', 'event', {
                            eventCategory: 'lead_general',
                            eventAction: 'button',
                            eventLabel: 'flink_ios'
                        });
                        self.wizardService.getLink('435158', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                        .then(function(result){
                            self.window.location.href = result.detail;
                        })
                    }else{
                        if(self.rs.duplicated){
                            self.window.fbq('trackSingleCustom', '2508338796109313','lead_duplicated', {
                                lender: 'flink_android',
                                currency: 'USD',
                                value: '0.20',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.fbq('trackSingleCustom', '2257303141245847','lead_duplicated', {
                                lender: 'flink_android',
                                currency: 'USD',
                                value: '0.20',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_duplicated',
                                eventAction: 'button',
                                eventLabel: 'flink_android'
                            });
                        }else{
                            self.window.fbq('trackSingleCustom', '2508338796109313','lead_new', {
                                lender: 'flink_android',
                                currency: 'USD',
                                value: '0.60',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.fbq('trackSingleCustom', '2257303141245847','lead_new', {
                                lender: 'flink_android',
                                currency: 'USD',
                                value: '0.60',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_new',
                                eventAction: 'button',
                                eventLabel: 'flink_android'
                            });
                        }
                        self.window.fbq('trackSingleCustom', '2508338796109313','lead_general', {
                            lender: 'flink_android',
                            currency: 'USD',
                            value: '0.40',
                            lead: {
                                id: self.rs.lead.id
                            }
                        });
                        self.window.fbq('trackSingleCustom', '2257303141245847','lead_general', {
                            lender: 'flink_android',
                            currency: 'USD',
                            value: '0.40',
                            lead: {
                                id: self.rs.lead.id
                            }
                        });
                        self.window.ga('send', 'event', {
                            eventCategory: 'lead_general',
                            eventAction: 'button',
                            eventLabel: 'flink_android'
                        }); 
                        self.wizardService.getLink('435157', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                        .then(function(result){
                            self.window.location.href = result.detail;
                        })
                    }
                })
                break;
            case 'KAPITAL':
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    if(deviceDetails.OS == 'iOS'){
                        if(self.rs.duplicated){
                            self.window.fbq('trackSingleCustom', '2508338796109313','lead_duplicated', {
                                lender: 'kapital_ios',
                                currency: 'USD',
                                value: '0.20',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.fbq('trackSingleCustom', '2257303141245847','lead_duplicated', {
                                lender: 'kapital_ios',
                                currency: 'USD',
                                value: '0.20',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_duplicated',
                                eventAction: 'button',
                                eventLabel: 'kapital_ios'
                            });
                        }else{
                            self.window.fbq('trackSingleCustom', '2508338796109313','lead_new', {
                                lender: 'kapital_ios',
                                currency: 'USD',
                                value: '0.60',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.fbq('trackSingleCustom', '2257303141245847','lead_new', {
                                lender: 'kapital_ios',
                                currency: 'USD',
                                value: '0.60',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_new',
                                eventAction: 'button',
                                eventLabel: 'kapital_ios'
                            });
                        }
                        self.window.fbq('trackSingleCustom', '2508338796109313','lead_general', {
                            lender: 'kapital_ios',
                            currency: 'USD',
                            value: '0.40',
                            lead: {
                                id: self.rs.lead.id
                            }
                        });
                        self.window.fbq('trackSingleCustom', '2257303141245847','lead_general', {
                            lender: 'kapital_ios',
                            currency: 'USD',
                            value: '0.40',
                            lead: {
                                id: self.rs.lead.id
                            }
                        });
                        self.window.ga('send', 'event', {
                            eventCategory: 'lead_general',
                            eventAction: 'button',
                            eventLabel: 'kapital_ios'
                        });
                        self.wizardService.getLink('435681', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                        .then(function(result){
                            self.window.location.href = result.detail;
                        })
                    }else{
                        if(self.rs.duplicated){
                            self.window.fbq('trackSingleCustom', '2508338796109313','lead_duplicated', {
                                lender: 'kapital_android',
                                currency: 'USD',
                                value: '0.20',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.fbq('trackSingleCustom', '2257303141245847','lead_duplicated', {
                                lender: 'kapital_android',
                                currency: 'USD',
                                value: '0.20',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_duplicated',
                                eventAction: 'button',
                                eventLabel: 'kapital_android'
                            });
                        }else{
                            self.window.fbq('trackSingleCustom', '2508338796109313','lead_new', {
                                lender: 'kapital_android',
                                currency: 'USD',
                                value: '0.60',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.fbq('trackSingleCustom', '2257303141245847','lead_new', {
                                lender: 'kapital_android',
                                currency: 'USD',
                                value: '0.60',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_new',
                                eventAction: 'button',
                                eventLabel: 'kapital_android'
                            });
                        }
                        self.window.fbq('trackSingleCustom', '2508338796109313', 'lead_general', {
                            lender: 'kapital_android',
                            currency: 'USD',
                            value: '0.40',
                            lead: {
                                id: self.rs.lead.id
                            }
                        });
                        self.window.fbq('trackSingleCustom', '2257303141245847', 'lead_general', {
                            lender: 'kapital_android',
                            currency: 'USD',
                            value: '0.40',
                            lead: {
                                id: self.rs.lead.id
                            }
                        });
                        self.window.ga('send', 'event', {
                            eventCategory: 'lead_general',
                            eventAction: 'button',
                            eventLabel: 'kapital_android'
                        }); 
                        self.wizardService.getLink('435679', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                        .then(function(result){
                            self.window.location.href = result.detail;
                        })
                    }
                })
                break;
                case 'BKAPITAL':
                    self.rs.lead.xsell = product.name.toUpperCase();
                    self.wizardService.updateLeadChild(self.rs.lead)
                    .then(upd => {
                        if(deviceDetails.OS == 'iOS'){
                            if(self.rs.duplicated){
                                self.window.fbq('trackSingleCustom', '457522735264304','lead_duplicated', {
                                    lender: 'bkapital_ios',
                                    currency: 'USD',
                                    value: '0.20',
                                    lead: {
                                        id: self.rs.lead.id
                                    }
                                });
                                self.window.ga('send', 'event', {
                                    eventCategory: 'lead_duplicated',
                                    eventAction: 'button',
                                    eventLabel: 'bkapital_ios'
                                });
                            }else{
                                self.window.fbq('trackSingleCustom','457522735264304', 'lead_new', {
                                    lender: 'bkapital_ios',
                                    currency: 'USD',
                                    value: '0.60',
                                    lead: {
                                        id: self.rs.lead.id
                                    }
                                });
                                self.window.ga('send', 'event', {
                                    eventCategory: 'lead_new',
                                    eventAction: 'button',
                                    eventLabel: 'bkapital_ios'
                                });
                            }
                            self.window.fbq('trackSingleCustom', '457522735264304','lead_general', {
                                lender: 'bkapital_ios',
                                currency: 'USD',
                                value: '0.40',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_general',
                                eventAction: 'button',
                                eventLabel: 'bkapital_ios'
                            });
                            self.wizardService.getLink('435681', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                            .then(function(result){
                                self.window.location.href = result.detail;
                            })
                        }else{
                            if(self.rs.duplicated){
                                self.window.fbq('trackSingleCustom', '457522735264304','lead_duplicated', {
                                    lender: 'bkapital_android',
                                    currency: 'USD',
                                    value: '0.20',
                                    lead: {
                                        id: self.rs.lead.id
                                    }
                                });
                                self.window.ga('send', 'event', {
                                    eventCategory: 'lead_duplicated',
                                    eventAction: 'button',
                                    eventLabel: 'bkapital_android'
                                });
                            }else{
                                self.window.fbq('trackSingleCustom', '457522735264304', 'lead_new', {
                                    lender: 'bkapital_android',
                                    currency: 'USD',
                                    value: '0.60',
                                    lead: {
                                        id: self.rs.lead.id
                                    }
                                });
                                self.window.ga('send', 'event', {
                                    eventCategory: 'lead_new',
                                    eventAction: 'button',
                                    eventLabel: 'bkapital_android'
                                });
                            }
                            self.window.fbq('trackSingleCustom', '457522735264304', 'lead_general', {
                                lender: 'bkapital_android',
                                currency: 'USD',
                                value: '0.40',
                                lead: {
                                    id: self.rs.lead.id
                                }
                            });
                            self.window.ga('send', 'event', {
                                eventCategory: 'lead_general',
                                eventAction: 'button',
                                eventLabel: 'bkapital_android'
                            }); 
                            self.wizardService.getLink('435679', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                            .then(function(result){
                                self.window.location.href = result.detail;
                            })
                        }
                    })
                    break;
            case 'MONEYMAN':
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('419168', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'CREZU':
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('434566', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'PEZETITA':
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('434524', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'ANDACREDITO':
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('436758', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'STORICARD':
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('435519', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            default:
                self.state.go('thankyou', {
                    full_app: true,
                    lead_id: self.rs.lead.id,
                    lead_email: self.rs.lead.email
                });
                break;
        }
    },
    cancel: function () {
        this.dismiss({ $value: 'cancel' });
    },
};
