angular
  .module('app')
  .component('headerComponent', {
    templateUrl: 'app/components/header/header.component.html',
    controller: Header
  });

/** @ngInject */
function Header($document, $state) {
  this.document = $document;
  this.state = $state;
  var menuSelect = '';
}

Header.prototype = {

  toSection: function (menu) {
    //validate state
    if (this.state.is('app')) {
      // get section element
      var section = angular.element(this.document[0].getElementById(menu));
      // animate scroll to section
      this.document.scrollToElement(section, 0, 500);
    } else {
      this.state.go('app');      
    }
    this.menuSelect = menu;
    
  },
  

  doActiveMenu: function (menu) {
    console.log("Here");
    // remove active class from other element
    var activeElements = this.document[0].getElementsByClassName('active');
    angular.element(activeElements[0]).removeClass("active");    
    this.menuSelect = menu;       
  }

};
