angular
  .module('app')
  .component('step6Component', {
    templateUrl: 'app/components/wizard/step6.component.html',
    controller: Step6
  });

/** @ngInject */
function Step6($rootScope, $state, $window) {
  this.rs = $rootScope;
  this.state = $state;
  this.showSpinner = false;
  this.message = '';
  if(!this.rs.lead.id){
    this.state.go('app');
  }
  this.window = $window;
  this.window.fbq('track', 'ViewContent');
  this.window.dataLayer.push({
        'event': 'wizard',
        'step': 2
  });
}

Step6.prototype = {
  changeToStep6 : function(){
    var self = this;
    self.showSpinner = true;
    if (self.rs.lead.interest == undefined || self.rs.lead.interest == '') {
      self.message = 'Favor de seleccionar una opción<br>'
      self.showSpinner = false;
      return;
    }else{
      // if(self.rs.lead.interest == 't_credito'){
      //   self.state.go('step7');
      // }else{
      //   self.state.go('step4');
      // }
      self.state.go('step7');
    }
    
  }
};