angular
  .module('app')
  .component('step3Component', {
    templateUrl: 'app/components/wizard/step3.component.html',
    controller: Step3
  });

/** @ngInject */
function Step3($rootScope, $state, $window, wizardService) {
  this.rs = $rootScope;
  this.state = $state;
  this.window = $window;
  this.showSpinner = false;
  this.wizardService = wizardService;
  if(!this.rs.lead.id){
    this.state.go('app');
  }
  this.window.dataLayer.push({
    'event': 'wizard',
    'step': 4
  });
  // this.window.dataLayer.push({
  //   'event': 'Pageview_TOP',
  //   'pagePath': '/step2',
  //   'pageTitle': 'Step2' //some arbitrary name for the page/state
  // });
  // this.window.ga('send', 'pageview', '/step2');
  this.window.fbq('track', 'ViewContent');
  var self = this;
  self.onesignal_notification_permission = this.window.OneSignal.Notifications.permission;
}
Step3.prototype = {
  changeToStep4 : function(){
    var self = this;
    self.showSpinner = true;
    self.wizardService.getSetting("name=TOPTARJTEAS_AFID")
    .then(function(data){
      const afids = JSON.parse(data.data[0].value);
      const f = _.find(afids, function(value) { return value == self.rs.lead.utm_source; });
      var ms = 0;
      switch (self.rs.lead.monthly_salary) {
          case 'menos_de__4_000':
              ms = 4000;
              break;
          case 'más_de__4_000_y_menos_de__7_000':
              ms = 5000;
              break;
          case 'más_de__7_000_y_menos_de__15_000':
              ms = 7000;
              break;
          case 'más_de__15_000_y_menos_de__30_000':
              ms = 16000;
              break;
          case 'más_de__30_000_y_menos_de__50_000':
              ms = 30000;
              break;
          case 'más_de__50_000':
              ms = 50000;
              break;
      }

      // console.log("Status one signal:: ", self.onesignal_notification_permission);
      // OneSignal.Debug.setLogLevel("trace"); Debbugear lo que hace OneSignal
      if(self.onesignal_notification_permission){ // Si tiene los permisos
        if(OneSignal.User.PushSubscription.token){ // Si esta suscrito se agrega sus nuevas subcripciones a Push, SMS y Email junto con su tag.
          // console.log("Ya esta suscrito: ",  OneSignal.User.PushSubscription.token);
          let tags = {
            email: self.rs.lead.email,
            telefono: self.rs.lead.mobile_number
          };

          if(self.rs.lead.utm_source != 422827 && self.rs.lead.utm_source != 422168){
            tags.utm_source = self.rs.lead.utm_source;
          }

          OneSignal.User.addTags(tags);
          self.wizardService.validEmail(self.rs.lead.email)
          .then(valid => {
            if(valid.status == 200){
              if(valid.data.status == 'valid'){
                OneSignal.User.addEmail(valid.data.email);
              }
            }
          })
          OneSignal.User.addSms("+52"+self.rs.lead.mobile_number);
        }else{ // Si no esta sucrito se le muestra de nuevo el prompt
          OneSignal.Slidedown.promptPush({ force: true });
        }
      }else{ // Si no tiene ni permiso se le muestra de nuevo el prompt
        OneSignal.Slidedown.promptPush({ force: true });
      }

      // Función que escucha cuando el usuario le da permitir en las notificaciones y se subcribe a los 3 canales (Push, Email y SMS)
      OneSignal.User.PushSubscription.addEventListener("change", function(event){
        if(event.current.token){ // El usuario se susbcribio, es decir le dio permitir a las notificaciones y se agrega la suscripción a los 3 canales (Push, Email y SMS)
          OneSignal.login(self.rs.lead.id.toString()); // Login para guardar el external id y asociarlo a sus subcripciones de canales
          let tags = {
            email: self.rs.lead.email,
            telefono: self.rs.lead.mobile_number
          };

          if(self.rs.lead.utm_source != 422827 && self.rs.lead.utm_source != 422168){
            tags.utm_source = self.rs.lead.utm_source;
          }
          
          OneSignal.User.addTags(tags);
          self.wizardService.validEmail(self.rs.lead.email)
          .then(valid => {
            if(valid.status == 200){
              if(valid.data.status == 'valid'){
                OneSignal.User.addEmail(valid.data.email);
              }
            }
          })
          OneSignal.User.addSms("+52"+self.rs.lead.mobile_number);
        }
      });

      self.state.go('step4');
    })
  }
};