var mainApp = angular
  .module('app', [
    'ui.router',
    'project.config',
    'restangular',
    'ngAnimate',
    'ngSanitize',
    'duScroll',
    'ngStorage',
    'ui.bootstrap',
    'sprintf',
    'ngCookies'
  ])
  .config(config)
  .constant('_', window._)
  .controller('indexController', IndexController);



/** @ngInject */
function config(EnvironmentConfig, RestangularProvider,$locationProvider) {
  //config Restangular
  RestangularProvider.setBaseUrl(EnvironmentConfig.site.rest.baseUrl);  
  var token = EnvironmentConfig.site.credentials.user + ':' + EnvironmentConfig.site.credentials.pass;
  var tokenHashed = btoa(token);

  RestangularProvider.setDefaultHeaders({'Authorization': 'Basic ' + tokenHashed });
}

/** @ngInject */
function IndexController($rootScope, $localStorage){
  console.log('************************************************************************************');
  console.log('************************************************************************************');
  console.log('**   ____       _  ____ ______   _______ ______ _____ _    _ _   _  ____   _____  **');
  console.log('**  / __ \\     | |/ __ \\____  | |__   __|  ____/ ____| |  | | \\ | |/ __ \\ / ____| **');
  console.log('** | |  | |    | | |  | |  / /     | |  | |__ | |    | |__| |  \\| | |  | | (___   **');
  console.log('** | |  | |_   | | |  | | / /      | |  |  __|| |    |  __  | . ` | |  | |\\___ \\  **');
  console.log('** | |__| | |__| | |__| |/ /       | |  | |___| |____| |  | | |\\  | |__| |____) | **');
  console.log('**  \\____/ \\____/ \\____//_/        |_|  |______\\_____|_|  |_|_| \\_|\\____/|_____/  **');
  console.log('**                                                                                **');
  console.log('************************************************************************************');
  console.log('************************************************************************************');

  // create global object for save lead options
  $rootScope.lead = {
    name : '',
    lastname: '',
    email : '',
    mobile_number : '',
    has_credit : '',
    good_payer : '',
    monthly_income : '',
    interest : '',
    question_card_credit: 3,
    accept_terms : false,
    accept_privacy : false,
    device_details : '',
    entry_url: '',
    bank: '',
  }

  $rootScope.force = '';
  $rootScope.withParams = false;
  $rootScope.params_extend = {};
  $rootScope.duplicated = false;
  $rootScope.$on('$locationChangeSuccess', function () {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }); 

}