angular
  .module('app')
  .component('stepEComponent', {
    templateUrl: 'app/components/wizard/stepE.component.html',
    controller: StepE
  });

/** @ngInject */
function StepE($state, $rootScope, wizardService) {
  this.state = $state;
  this.wizardService = wizardService;
  this.rs = $rootScope;
  this.showSpinner = false;
  const self = this;
  this.disabled = false;
  if(!this.rs.lead.id){
    this.state.go('app');
  }

  setTimeout(function(){
    if(self.disabled){
      self.state.go('step2');
    }else{
      self.wizardService.sendSms({phone: self.rs.lead.mobile_number, email: self.rs.lead.email, utm_source: self.rs.lead.utm_source, sid: self.rs.lead.sid })
      .then(function(smsSent) {
        self.state.go('step2');
      })
    }
    
  }, 6000)

}

StepE.prototype = {
  changeToStep2: function () {
    const self = this;
    self.showSpinner = true;
    self.disabled = true; 
    self.wizardService.sendSms({phone: self.rs.lead.mobile_number, email: self.rs.lead.email, utm_source: self.rs.lead.utm_source, sid: self.rs.lead.sid })
    .then(function(smsSent) {
      self.state.go('step2');  
      self.showSpinner = false;  
    })
  }
};