angular
  .module('app')
  .component('footerComponent', {
    templateUrl: 'app/components/footer/footer.component.html',
    controller: Footer    
  });

function Footer() {
 
}

Footer.prototype = {
  
};

