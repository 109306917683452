angular
  .module('app')
  .component('paso3SelectorBanamex', {
    templateUrl: 'app/components/selectorcitibanamex/paso3/paso3SelectorBanamex.component.html',
    controller: Paso3
  });

/** @ngInject */
function Paso3($rootScope, $state, $window) {
  this.rs = $rootScope;
  this.state = $state;
  if(!this.rs.lead.id){
    this.state.go('selectorBanamexPaso1');
  }

  this.window = $window;
  this.window.fbq('track', 'ViewContent');

  // var self = this;
  // this.window.OneSignal.push(["getNotificationPermission", function(permission) {
  //   self.onesignal_notification_permission = permission;
  // }]);
}

Paso3.prototype = {
  changeToPaso4 : function(){
    this.state.go('selectorBanamexPaso4');
  }
};