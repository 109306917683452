angular
  .module('app')
  .component('paso2SelectorBanamex', {
    templateUrl: 'app/components/selectorcitibanamex/paso2/paso2SelectorBanamex.component.html',
    controller: Paso2
  });

/** @ngInject */
function Paso2($rootScope, $state, $window) {
    this.rs = $rootScope;
    this.state = $state;
    if(!this.rs.lead.id){
        this.state.go('selectorBanamexPaso1');
    }
    this.window = $window;
    this.window.fbq('track', 'ViewContent');

}

Paso2.prototype = {
    changeToPaso3 : function(){
        var self = this;
        if(self.rs.lead.question_card_credit == 1){
          self.trackPixel = "https://ojo7.ltroute.com/pixel.track?CID=437380&stepId=5463&MerchantReferenceID=" +
              self.rs.lead.mobile_number + "_" + self.rs.lead.email + "_track";
          
          setTimeout(function(){
            self.state.go('selectorBanamexPaso3');
          }, 1000)
          
        }else{
          self.trackPixel = "https://ojo7.ltroute.com/pixel.track?CID=437380&stepId=5464&MerchantReferenceID=" +
              self.rs.lead.mobile_number + "_" + self.rs.lead.email + "_track";
          self.rs.lead.bank = 'SinBanco';
          setTimeout(function(){
            self.state.go('selectorBanamexPaso4');
          }, 1000)
          
        }
         
    },
    changeToPaso1: function(){
      var self = this;
      window.location.href = '/selectorcitibanamex' + self.rs.queryString;
    }
};