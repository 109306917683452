angular
  .module('app')
  .component('step2Component', {
    templateUrl: 'app/components/wizard/step2.component.html',
    controller: Step2
  });

/** @ngInject */
function Step2($state, $rootScope) {
  this.state = $state;
  this.rs = $rootScope;

  if(!this.rs.lead.id){
    this.state.go('app');
  }


}

Step2.prototype = {
  changeToStep3: function () {

    if (this.rs.lead.has_credit && this.rs.lead.good_payer) {
      this.state.go('step3');
    }else{
      this.state.go('otherOptions');
    }
    
  }
};