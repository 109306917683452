angular
    .module('app')
    .service('productService', ProductService);

/** @ngInject */
function ProductService(Restangular, $q) {
    this.restangular = Restangular;
    this.q = $q;
}

ProductService.prototype = {
    getProductByFriendlyName: function (friendly_name) {
        var deferred = this.q.defer();
        var promise = deferred.promise;
        this.restangular.one('toptarjetas/v2', '')
            .one('product', '')
            .one('name', friendly_name)
            .get()
            .then(function (response) {
                // resolve the promise
                deferred.resolve(response.plain());
            });

        //return the promise
        return promise;
    },
}