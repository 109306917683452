angular
    .module('app')
    .component('modalFormAztecaComponent', {
        templateUrl: 'app/components/azteca/modalFormAzteca/modalFormAzteca.component.html',
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        },
        controller: ModalFormAzteca,
    });

/** @ngInject */
function ModalFormAzteca(wizardService, $cookies, $timeout, EnvironmentConfig, $window) {
    // init variables
    this.EnvironmentConfig = EnvironmentConfig;
    this.wizardService = wizardService;
    this.cookies = $cookies;
    this.timeout = $timeout;
    this.window = $window;
    // patterns for validates inputs text
    this.phonePattern = "\\d+";
    this.emailPattern = /^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i;
    // show email invalid message
    this.invalidEmail = false;
    // show ajax spinner
    this.showSpinner = false;
    // lead data
    this.email = "";
    this.phone = "";
    this.campaign_params = JSON.parse(this.cookies.get('campaign_params'));
    this.entry_url = this.cookies.get('entry_url');
};


ModalFormAzteca.prototype = {

    // this function executes when modal-dialog opens
    $onInit: function() {
        this.product = this.resolve.product;
    },

    // this function executes when the user clicks ok butto
    ok: function() {
        var self = this;
        const extend_params = {
            sub_id: self.generate_sub_id(self.campaign_params.utm_source, self.campaign_params.sub_id),
            utm_medium: self.campaign_params.utm_medium ? self.campaign_params.utm_medium : "",
            utm_campaign: self.campaign_params.utm_campaign ? self.campaign_params.utm_campaign : "",
            utm_term: self.campaign_params.utm_term ? self.campaign_params.utm_term : "",
            utm_content: self.campaign_params.utm_content ? self.campaign_params.utm_content : "",
            utm_nooverride: self.campaign_params.utm_nooverride ? self.campaign_params.utm_nooverride : "",
            origin: self.campaign_params.origin ? self.campaign_params.origin : "",
            email_delivery_id: self.campaign_params.email_delivery_id ? self.campaign_params.email_delivery_id  : "",
            email_commission_campaign: self.campaign_params.email_commission_campaign ? self.campaign_params.email_commission_campaign : "",
            t_num: self.campaign_params.t_num ? self.campaign_params.t_num : "",
            t_email: self.campaign_params.t_email ? self.campaign_params.t_email: "",
          };
        // validate duplicated lead
        self.wizardService.validateDuplicatedLead(self.email, self.phone).then(function(dataDuplicated) {
            self.showSpinner = true;
            // get client ip
            self.wizardService.getIpClient().then(function(clientData) {
                // calls leadService for validate email and create the lead
                self.wizardService.createFullLead({
                    email: self.email,
                    mobile_number: self.phone,
                    utm_source: self.campaign_params.utm_source,
                    entry_url: self.entry_url,
                    chosen_product: self.product.id,
                    status: 'COMPLETE',
                    user_ip: clientData.data.ip,
                    device_details: JSON.stringify(getDetails()),
                    sid: self.campaign_params.SID,
                    sent_to_bnmx: false,
                    loan: 0,
                    return_days:80,
                    application_path: 'TOP_TARJETAS',
                }).then(function(data) {
                    if (dataDuplicated.duplicated) {
                        // step duplicated convertion
                        self.trackPixel = "https://ojo7.ltroute.com/pixel.track?CID=420775&stepId=2615&MerchantReferenceID=" +
                            data.id + "_" + data.email;
                    } else {
                        // new convertion 
                        self.trackPixel = "https://ojo7.ltroute.com/pixel.track?CID=420775&savecookie=x&MerchantReferenceID=" +
                            data.id + "_" + data.email;
                    }
                    // redirect to client landing              
                    self.timeout(function() {
                        const utm = self.campaign_params.utm_source;
                        const deviceDetails = getDetails();
                        if(deviceDetails.OS == 'iOS'){
                            self.wizardService.getLink('430147', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, extend_params)
                            .then(function(result){
                                // console.log(result);
                                self.window.location.href = result.detail;
                            })
                        }else{
                            self.wizardService.getLink('430148', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, extend_params)
                            .then(function(result){
                                self.window.location.href = result.detail;
                                // console.log(result);
                            })
                        }
                    }, 1000);
                });
            });
        });

    },

    // this function executes when the user clicks cancel button
    cancel: function() {
        this.dismiss({ $value: 'cancel' });
    },

    // this function restrict only numbers in input text
    onlyNumers: function(event) {
        const pattern = /[0-9\+\-\ ]/;
        var inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    },
    isInteger: function (data) {
        try {
          if (typeof data === 'number') {
            var remainder = data % 1
            if (remainder === 0) {
              return true
            }
          }
          return false
        } catch (err) {
          return false
        }
      },
    validate_utm_source: function (utm_source) {
        // validates if utm_source is integer
        if (!this.isInteger(utm_source * 1)
          || utm_source * 1 < 418761) {
          // set utm_source for organic origin (does not belong to a valid campaign )
          return false;
        }
        return true;
      },
    
      generate_sub_id: function (utm_source, sub_id) {
        // if utm_source isn't valid and sub_id is empty the sub_id becomes
        // in the original utm_source
        if (!this.validate_utm_source(utm_source)) {
          // utm_source no valid, campaign no valid
          if (sub_id == '' || typeof sub_id === 'undefined') {
            // if sub_id empty or undefined return utm_source
            if (utm_source == '' || typeof utm_source === 'undefined') {
              return '';
            }
            return utm_source;
          } else {
            // if sub_id is full return sub_id
            return sub_id;
          }
        } else {
          // utm_source valid
          if (sub_id == '' || typeof sub_id === 'undefined') {
            // if sub_id empty or undefined return empty string
            return "";
          } else {
            // if sub_id is full return sub_id
            return sub_id;
          }
        }
    
      }
};