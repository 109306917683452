angular
  .module('app')
  .component('step7Component', {
    templateUrl: 'app/components/wizard/step7.component.html',
    controller: Step7
  });

/** @ngInject */
function Step7($rootScope, $state, $window) {
    this.rs = $rootScope;
    this.state = $state;
    this.showSpinner = false;
    if(!this.rs.lead.id){
        this.state.go('app');
    }
    this.window = $window;
    this.window.fbq('track', 'ViewContent');
    this.window.dataLayer.push({
      'event': 'wizard',
      'step': 3
    });
}

Step7.prototype = {
    changeToStep7 : function(){
        var self = this;
        self.showSpinner = true;
        self.state.go('step2'); 
    }
};