angular
    .module('app')
    .service('contactService', ContactService);

/** @ngInject */
function ContactService(Restangular, $q) {
    this.restangular = Restangular;
    this.q = $q;
}

ContactService.prototype = {

    sendMessage: function(message) {
        var deferred = this.q.defer();
        var promise = deferred.promise;

        this.restangular.all('/email/send')
            .post({
                from: 'info@toptarjetas.mx',
                to: 'info@toptarjetas.mx',
                subject: 'Preguntas top tarjetas',
                html_template: message
            }).then(function(response) {
                // resolve the promise
                deferred.resolve(response.plain());
            });
        return promise;
    },

    sendEmailMessage: function (from, to, message, name){
        var deferred = this.q.defer();
        var promise = deferred.promise;

        this.restangular.all('/email/send')
            .post({
                from,
                to,
                cc: ['elizabeth@ojo7.com'],
                subject: 'Página Web::Afiliados - ' + name,
                html_template: message
            }).then(function(response) {
                // resolve the promise
                deferred.resolve(response.plain());
            });
        return promise;
    }
}