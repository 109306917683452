angular
    .module('app')
    .component('graciasComponent', {
        templateUrl: 'app/components/result/graciasBanamex.component.html',
        controller: Gracias
    });

/** @ngInject */
function Gracias($rootScope, $stateParams, $state) {
    this.rs = $rootScope;
    this.sp = $stateParams;
    this.state = $state;
    this.trackPixel = "https://ojo7.ltroute.com/pixel.track?CID=437380&stepId=5466&MerchantReferenceID=" +
              this.rs.lead.mobile_number + "_" + this.rs.lead.email + "_track";
}

Gracias.prototype = {
    changeToPaso1: function(){
        var self = this;
        window.location.href = '/selectorcitibanamex' + self.rs.queryString;
    }
}