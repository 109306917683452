angular
    .module('app')
    .component('modalCitiBanamexComponent', {
        templateUrl: 'app/components/citibanamex/modalCitiBanamex/modalCitiBanamex.component.html',
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        },
        controller: ModalCitiBanamex,
    });

/** @ngInject */
function ModalCitiBanamex(wizardService, $cookies, $timeout, EnvironmentConfig, $window) {
    // init variables
    this.EnvironmentConfig = EnvironmentConfig;
    this.wizardService = wizardService;
    this.cookies = $cookies;
    this.timeout = $timeout;
    this.window = $window;
    // patterns for validates inputs text
    // this.phonePattern = "\\d+";
    // this.emailPattern = /^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i;
    // show email invalid message
    // this.invalidEmail = false;
    // show ajax spinner
    // this.showSpinner = false;
    // lead data
    // this.email = "";
    // this.phone = "";
    this.campaign_params = JSON.parse(this.cookies.get('campaign_params'));
    this.entry_url = this.cookies.get('entry_url');
};


ModalCitiBanamex.prototype = {

    // this function executes when modal-dialog opens
    $onInit: function() {
        this.product = this.resolve.product;
        const self = this;

        switch(this.product){
            case 'clasica':
                self.wizardService.getAffilate(self.campaign_params.utm_source)
                .then(resp => {
                    if(resp.status === 'OK'){
                        const type = resp.detail.type;
                        const CID = type === 'INTERNAL' ? '411908' : '432569';
                        const ADID = type === 'INTERNAL' ? '2431601' : '2431607';
                        window.location.href = `https://ojo7.ltroute.com/click.track?CID=${CID}&AFID=${self.campaign_params.utm_source}&ADID=${ADID}&SID=TopTarjetas-${self.campaign_params.SID}`;
                    }
                })
                break;
            case 'oro':
                self.wizardService.getAffilate(self.campaign_params.utm_source)
                .then(resp => {
                    if(resp.status === 'OK'){
                        const type = resp.detail.type;
                        const CID = type === 'INTERNAL' ? '411908' : '432569';
                        const ADID = type === 'INTERNAL' ? '2431603' : '2431609';
                        window.location.href = `https://ojo7.ltroute.com/click.track?CID=${CID}&AFID=${self.campaign_params.utm_source}&ADID=${ADID}&SID=TopTarjetas-${self.campaign_params.SID}`;
                    }
                })
                break;
            case 'platinum':
                self.wizardService.getAffilate(self.campaign_params.utm_source)
                .then(resp => {
                    if(resp.status === 'OK'){
                        const type = resp.detail.type;
                        const CID = type === 'INTERNAL' ? '411908' : '432569';
                        const ADID = type === 'INTERNAL' ? '2431604' : '2431610';
                        window.location.href = `https://ojo7.ltroute.com/click.track?CID=${CID}&AFID=${self.campaign_params.utm_source}&ADID=${ADID}&SID=TopTarjetas-${self.campaign_params.SID}`;
                    }
                })
                break;
            case 'rewards':
                self.wizardService.getAffilate(self.campaign_params.utm_source)
                .then(resp => {
                    if(resp.status === 'OK'){
                        const type = resp.detail.type;
                        const CID = type === 'INTERNAL' ? '411908' : '432569';
                        const ADID = type === 'INTERNAL' ? '2431606' : '2431612';
                        window.location.href = `https://ojo7.ltroute.com/click.track?CID=${CID}&AFID=${self.campaign_params.utm_source}&ADID=${ADID}&SID=TopTarjetas-${self.campaign_params.SID}`;
                    }
                })
                break;
            case 'premier':
                self.wizardService.getAffilate(self.campaign_params.utm_source)
                .then(resp => {
                    if(resp.status === 'OK'){
                        const type = resp.detail.type;
                        const CID = type === 'INTERNAL' ? '411908' : '432569';
                        const ADID = type === 'INTERNAL' ? '2431605' : '2431611';
                        window.location.href = `https://ojo7.ltroute.com/click.track?CID=${CID}&AFID=${self.campaign_params.utm_source}&ADID=${ADID}&SID=TopTarjetas-${self.campaign_params.SID}`;
                    }
                })
                break;
            default:
                self.wizardService.getAffilate(self.campaign_params.utm_source)
                .then(resp => {
                    if(resp.status === 'OK'){
                        const type = resp.detail.type;
                        const CID = type === 'INTERNAL' ? '411908' : '432569';
                        const ADID = type === 'INTERNAL' ? '2431601' : '2431607';
                        window.location.href = `https://ojo7.ltroute.com/click.track?CID=${CID}&AFID=${self.campaign_params.utm_source}&ADID=${ADID}&SID=TopTarjetas-${self.campaign_params.SID}`;
                    }
                })
                break;
        }
    },

    // this function executes when the user clicks ok butto
    // ok: function() {
    //     var self = this;
    // },

    // this function executes when the user clicks cancel button
    // cancel: function() {
    //     this.dismiss({ $value: 'cancel' });
    // },

    // this function restrict only numbers in input text
    // onlyNumers: function(event) {
    //     const pattern = /[0-9\+\-\ ]/;
    //     var inputChar = String.fromCharCode(event.charCode);

    //     if (!pattern.test(inputChar)) {
    //         // invalid character, prevent input
    //         event.preventDefault();
    //     }
    // },
};