angular
  .module('app')
  .component('creditCarComponent', {
    templateUrl: 'app/components/creditCard/creditCar.component.html',
    controller: CreditCar
  });

/** @ngInject */
function CreditCar() {

}

CreditCar.prototype = {
  
};