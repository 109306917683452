angular
  .module('app')
  .component('otherOptionsComponent', {
    templateUrl: 'app/components/wizard/otherOptions.component.html',
    controller: OtherOptions
  });

/** @ngInject */
function OtherOptions($rootScope, wizardService, $state) {
  this.rs = $rootScope
  this.wizardService = wizardService;
  this.state = $state;

  if (!this.rs.lead.id) {
    this.state.go('app');
  } else {

    if(this.rs.lead.good_payer == ''){
      this.rs.lead.good_payer = false;
    };

    this.wizardService.updateLead(this.rs.lead).then(function (data) {
    });
  }



}

OtherOptions.prototype = {

};