angular
  .module('app')
  .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
    .state('app', {
      url: '/?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge&ttclid&fbclid',
      component: 'mainSection',
    })
    .state('creditCar', {
      url: '/creditCar',
      component: 'creditCarComponent'
    })
    .state('faq', {
      url: '/faq',
      component: 'faqComponent'
    })
    .state('afiliados', {
      url: '/afiliados',
      component: 'afiliadosComponent'
    })
    .state('step1', {
      url: '/step1?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge&ttclid&fbclid',
      component: 'step1Component',
      params: {
        main: false
      }
    })
    .state('stepE', {
      url: '/stepE',
      component: 'stepEComponent'
    })
    .state('step2', {
      url: '/step2',
      component: 'step3Component'
    })
    // .state('step3', {
    //   url: '/step3',
    //   component: 'step3Component'
    // })
    .state('step3', {
      url: '/step3',
      component: 'step4Component'
    })
    .state('step4', {
      url: '/step4',
      component: 'step5Component'
    })
    .state('step6', {
      url: '/step6',
      component: 'step6Component'
    })
    .state('step7', {
      url: '/step7',
      component: 'step7Component'
    })
    .state('gracias', {
      url: '/gracias',
      component: 'graciasComponent'
    })
    .state('result', {
      url: '/result',
      component: 'resultComponent',
      params: {
        products: null,
        lender: ''
      }
    })
    .state('result_banamex', {
      url: '/result_option',
      component: 'resultBanamexComponent',
      params: {
        products: null,
        lender: ''
      }
    })
    .state('additionalInfo', {
      url: '/additionalInfo',
      component: 'additionalInfoComponent',
      params: {
        product: null
      }
    })
    .state('thankyou', {
      url: '/thankyou',
      component: 'thankyouComponent',
      params: {
        full_app: null,
        lead_id: null,
        lead_email: null
      }
    })
    .state('thankyou_coru', {
      url: '/thankyou_coru',
      component: 'thankyouCoruComponent',
      params: {
        coru: {}
      }
    })
    .state('thankyou_ext', {
      url: '/coruaff?utm_source&utm_medium&utm_campaign&SID&id',
      component: 'thankyouAffiliatesComponent',
    })
    .state('otherOptions', {
      url: '/otherOptions',
      component: 'otherOptionsComponent'
    })
    .state('terms', {
      url: '/termsandconditions',
      templateUrl: 'app/components/legal/terms_and_conditions.html'
    })
    .state('notice_privacity', {
      url: '/noticeofprivacy',
      templateUrl: 'app/components/legal/notice_of_privacy.html'
    })
    .state('contact', {
      url: '/contact',
      component: 'contactComponent'
    })
    .state('mejores_tarjetas', {
      url: '/mejorestarjetas',
      templateUrl: 'app/components/articles/mejores_tarjetas.html'
    })
    .state('tarjetas_sin_anualidad', {
      url: '/tarjetassinanualidad',
      templateUrl: 'app/components/articles/tarjetas_sin_anualidad.html'
    })
    .state('tarjetas_estudiantes', {
      url: '/tarjetasestudiantes',
      templateUrl: 'app/components/articles/tarjetas_estudiantes.html'
    })
    .state('tarjetas_sin_buro', {
      url: '/tarjetassinburo',
      templateUrl: 'app/components/articles/tarjetas_sin_buro.html'
    })
    .state('tarjetas_sin_comprobar', {
      url: '/tarjetassincomprobar',
      templateUrl: 'app/components/articles/tarjetas_sin_comprobar.html'
    })
    .state('tarjetas_jovenes', {
      url: '/tarjetasjovenes',
      templateUrl: 'app/components/articles/tarjetas_jovenes.html'
    })
    .state('tarjetas_bajos_requisitos', {
      url: '/tarjetasbajosrequisitos',
      templateUrl: 'app/components/articles/tarjetas_bajos_requisitos.html'
    })
    .state('tarjetas_sin_historial', {
      url: '/tarjetassinhistorial',
      templateUrl: 'app/components/articles/tarjetas_sin_historial.html'
    })
    .state('tarjetas_viajes', {
      url: '/tarjetasviajes',
      templateUrl: 'app/components/articles/tarjetas_viajes.html'
    })
    .state('tarjetas_departamentales', {
      url: '/tarjetasdepartamentales',
      templateUrl: 'app/components/articles/tarjetas_departamentales.html'
    })
    .state('tarjetas_mastercard', {
      url: '/tarjetasmastercard',
      templateUrl: 'app/components/articles/tarjetas_mastercard.html'
    })
    .state('tarjetas_visa', {
      url: '/tarjetasvisa',
      templateUrl: 'app/components/articles/tarjetas_visa.html'
    })
    .state('tarjetas_amex', {
      url: '/tarjetasamex',
      templateUrl: 'app/components/articles/tarjetas_amex.html'
    })
    .state('tarjetas_garantizadas', {
      url: '/tarjetasgarantizadas',
      templateUrl: 'app/components/articles/tarjetas_garantizadas.html'
    })
    .state('mi_primera_tarjeta', {
      url: '/miprimeratarjeta',
      templateUrl: 'app/components/articles/mi_primera_tarjeta.html'
    })
    .state('tarjetas_platinum', {
      url: '/tarjetasplatinum',
      templateUrl: 'app/components/articles/tarjetas_platinum.html'
    })
    .state('tarjetas_credito_en_linea', {
      url: '/tarjetascreditoenlinea',
      templateUrl: 'app/components/articles/tarjetas_credito_en_linea.html'
    })
    .state('salir_de_deudas', {
      url: '/salirdedeudas',
      templateUrl: 'app/components/articles/salir_de_deudas.html'
    })
    .state('message_ok', {
      url: '/message',
      templateUrl: 'app/components/legal/message_ok.html'
    })
    .state('thankyou_fb', {
      url: '/thankyou_fb',
      templateUrl: 'app/components/thankyou_fb/thankyou_fb.html'
    })
    .state('amex', {
      url: '/amex?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'amexProductComponent'
    })
    .state('azteca', {
      url: '/azteca?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'aztecaProductComponent'
    })
    .state('citibanamex', {
      url: '/citibanamex?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'citibanamexxProductComponent'
    })
    .state('bbva', {
      url: '/bbva?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'bbvaProductComponent'
    })
    .state('bbva2', {
      url: '/bbva2?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'bbva2Component'
    })
    .state('iqos3', {
      url: '/iqos3?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'iqosTresProductComponent'
    })
    .state('amigo', {
      url: '/amigo?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'amigoProductComponent'
    })
    .state('mango-life', {
      url: '/mango-life?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'mangoLifeProductComponent'
    })
    .state('albo', {
      url: '/albo?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'alboProductComponent'
    })
    .state('adiosdeuda', {
      url: '/adiosdeuda?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'adiosDeudaProductComponent'
    })
    .state('lendon', {
      url: '/lendon?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'lendon'
    })
    .state('bnext', {
      url: '/bnext?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'bnextProductComponent'
    })
    .state('vexia', {
      url: '/vexi?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'vexiAProductComponent'
    })
    .state('vexib', {
      url: '/bvexi?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'vexiBProductComponent'
    })
    .state('iesca', {
      url: '/iesca?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'iescaProductComponent'
    })
    .state('uiprepa', {
      url: '/uiprepa?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'uiprepaProductComponent'
    })
    .state('uimaestria', {
      url: '/uimaestria?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'uimaestriaProductComponent'
    })
    .state('bedu', {
      url: '/bedu?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'beduProductComponent'
    })
    .state('utel', {
      url: '/utel?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'utelProductComponent'
    })
    .state('kitkat', {
      url: '/kitkat?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'kitkatProductComponent'
    })
    .state('creditoauto', {
      url: '/t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'crediAutoBbvaProductComponent'
    })
    .state('volvo', {
      url: '/volvo?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'volvoProductComponent'
    })
    .state('samsung', {
      url: '/samsung?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'samsungProductComponent'
    })
    .state('kueski', {
      url: '/kueski?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'kueskiProductComponent'
    })
    .state('flink', {
      url: '/flink?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'flinkProductComponent'
    })
    .state('flinkenvios', {
      url: '/flinkenvios?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'flinkEnviosProductComponent'
    })
    .state('tarjetasflink', {
      url: '/tarjetasflink?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'flinkdosProductComponent'
    })
    .state('flinkInversion', {
      url: '/flink-inversion?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'flinkInversionProductComponent'
    })
    .state('klar', {
      url: '/klar?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'klarProductComponent'
    })
    .state('clubPremier', {
      url: '/clubpremier?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'clubPremierProductComponent'
    })
    .state('dineria', {
      url: '/dineria?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'dineriaProductComponent'
    })
    .state('italika', {
      url: '/italika?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'italikaProductComponent'
    })
    .state('kueskicopy', {
      url: '/kueskicopy?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'kueskiCopyProductComponent'
    })
    .state('storicard', {
      url: '/storicard?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'storiCardProductComponent'
    })
    .state('totalplay', {
      url: '/totalplay?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'totalplayProductComponent'
    })
    .state('parneo', {
      url: '/parneo?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'parneoProductComponent'
    })
    .state('platzi', {
      url: '/platzi?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'platziProductComponent'
    })
    .state('selectorBanamex', {
      url: '/selectorcitibanamex?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'paso1SelectorBanamex'
    })
    .state('selectorBanamexPaso1', {
      url: '/selectorcitibanamex/paso1?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'paso1SelectorBanamex'
    })
    .state('selectorBanamexPaso2', {
      url: '/selectorcitibanamex/paso2?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'paso2SelectorBanamex'
    })
    .state('selectorBanamexPaso3', {
      url: '/selectorcitibanamex/paso3?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'paso3SelectorBanamex'
    })
    .state('selectorBanamexPaso4', {
      url: '/selectorcitibanamex/paso4?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'paso4SelectorBanamex'
    })
    .state('moneo', {
      url: '/moneo?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'moneoProductComponent'
    })
    .state('digitt', {
      url: '/digitt?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'digittProductComponent'
    })
    .state('uOberta', {
      url: '/universitat_oberta_de_catalunya?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'uObertaProductComponent'
    })
    .state('kapital', {
      url: '/kapital?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'kapitalProductComponent'
    })
    .state('payjoy', {
      url: '/payjoy?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'payjoyProductComponent'
    })
    .state('lifepoints', {
      url: '/lifepoints?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'lifePointsProductComponent'
    })
    .state('moneo2', {
      url: '/moneo2?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'moneo2ProductComponent'
    })
    .state('rappipay', {
      url: '/rappipay?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'rappiPayProductComponent'
    })
    .state('sky', {
      url: '/sky?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'skyProductComponent'
    })
    .state('crehana', {
      url: '/crehana?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'crehanaProductComponent'
    })
    .state('lahaus', {
      url: '/lahaus?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'laHausProductComponent'
    })
    .state('uinsurgentesEducacion', {
      url: '/uin-maestriaeneducacion?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'uinsurgentesEducacionProductComponent'
    })
    .state('uinsurgentesAdministracion', {
      url: '/uin-maestriaenadministraciondenegocios?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'uinsurgentesAdministracionProductComponent'
    })
    .state('rtd', {
      url: '/rtd?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'rtdProductComponent'
    })
    .state('nestleBaby', {
      url: '/nestlebaby?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'nestleBabyProductComponent'
    })
    .state('utelejecutivas', {
      url: '/utelejecutivas?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'utelEjecutivasProductComponent'
    })
    .state('coppelantenevenio', {
      url: '/coppelantenevenio?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'coppelAntevenioProductComponent'
    })
    .state('uala', {
      url: '/uala?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'ualaProductComponent'
    })
    .state('corutarjetas', {
      url: '/corutarjetas?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'coruProductComponent'
    })
    .state('arcavalley', {
      url: '/arcavalley?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'arcaValleyProductComponent'
    })
    .state('parallel', {
      url: '/parallel?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'parallelProductComponent'
    })
    .state('valepay', {
      url: '/valepay?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'valepayProductComponent'
    })
    .state('nubank', {
      url: '/nubank?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'nubankProductComponent'
    })
    .state('amigodiademuertos', {
      url: '/amigodiademuertos?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'amigoMuertosProductComponent'
    })
    .state('amigohalloween', {
      url: '/amigohalloween?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'amigoHalloweenProductComponent'
    })
    .state('amigobuenfin', {
      url: '/amigobuenfin?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'amigoBuenFinProductComponent'
    })
    .state('tucantonmx', {
      url: '/tucanton?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'tuCantonMxProductComponent'
    })
    .state('payjoyNavidad', {
      url: '/payjoynavidad?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'payjoyNavidadProductComponent'
    })
    .state('amigonavidad', {
      url: '/amigonavidad?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'amigoNavidadProductComponent'
    })
    .state('amigoañonuevo', {
      url: '/amigoañonuevo?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'amigoanionuevoProductComponent'
    })
    .state('cint', {
      url: '/cint?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'cintProductComponent'
    })
    .state('openenglish', {
      url: '/openenglish?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'openEnglishProductComponent'
    })
    .state('vivus', {
      url: '/vivus?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'vivusProductComponent'
    })
    .state('bbvadoublepay', {
      url: '/bbvadoublepay?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'bbvaDoublepayProductComponent'
    })
    .state('tuhabi', {
      url: '/tuhabi?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'tuHabiProductComponent'
    })
    .state('amexgold', {
      url: '/amexgold?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'amexOroProductComponent'
    })
    .state('amexplatinum', {
      url: '/amexplatinum?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'amexPlatinumProductComponent'
    })
    .state('amexblue', {
      url: '/amexblue?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'amexBlueProductComponent'
    })
    .state('amigomadres', {
      url: '/amigodiadelasmadres?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'amigoMadresProductComponent'
    })
    .state('moneyman', {
      url: '/moneyman?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'moneymannProductComponent'
    })
    .state('tucantonedm', {
      url: '/tucantonedm?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'tuCantonEdmProductComponent'
    })
    .state('tucantonqro', {
      url: '/tucantonqro?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'tuCantonQroProductComponent'
    })
    .state('amigopadre', {
      url: '/amigopadre?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'amigoFatherProductComponent'
    })
    .state('pandora', {
      url: '/pandora?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'pandoraProductComponent'
    })
    .state('pandoramoments', {
      url: '/pandoramoments?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'pandoraMomentsProductComponent'
    })
    .state('kueskipromocion', {
      url: '/kueskipromocion?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'kueskiPromocionProductComponent'
    })
    .state('kueskinavidad', {
      url: '/kueskinavidad?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'kueskiNavidadProductComponent'
    })
    .state('coru', {
      url: '/coru?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'coru2ProductComponent'
    })
    .state('amigopromociones', {
      url: '/amigopromociones?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'amigoPromocionProductComponent'
    })
    .state('vivuspromociones', {
      url: '/vivuspromociones?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'vivusPromocionesProductComponent'
    })
    .state('kloop', {
      url: '/kloop?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'kloopProductComponent'
    })
    .state('kueskiantevenio', {
      url: '/kueskiantevenio?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'kueskiAntevenioProductComponent'
    })
    .state('americanexpress', {
      url: '/americanexpress?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'americanExpressProductComponent'
    })
    .state('moneymarket', {
      url: '/moneymarket?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'moneymarketProductComponent'
    })
    .state('coppel', {
      url: '/coppel?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'coppelProductComponent'
    })
    .state('credito365', {
      url: '/credito365?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'credito365ProductComponent'
    })
    .state('kimbi', {
      url: '/kimbi?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'kimbiProductComponent'
    })
    .state('afirme', {
      url: '/afirme?t_num&t_email&utm_source&utm_medium&utm_campaign&origin&sub_id&utm_term&utm_content&utm_nooverride&click_id&SID&email&email_delivery_id&email_commission_campaign&affRefCidOrigin&gclid&id_bridge',
      component: 'afirmeProductComponent'
    })
}
