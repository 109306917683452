angular
    .module('app')
    .service('wizardService', WizardService);

/** @ngInject */
function WizardService(Restangular, $q, $http) {
    this.restangular = Restangular;
    this.q = $q;
    this.http = $http;
}

WizardService.prototype = {

    validEmail: function(email){
        var deferred = this.q.defer();
        var promise = deferred.promise;
        // create short lead
        this.http.post("https://lovelace.ojo7.com/email/validate", { email }, { timeout: 2000 }).then(function (response) {
            // resolve the promise
            deferred.resolve(response);
        }, function errorCallback(response) {
            deferred.resolve({ data: response });
        });
        //return the promise
        return promise;
    },

    createFullLead: function(lead) {
        var deferred = this.q.defer();
        var promise = deferred.promise;
        lead.referrer = document.referrer;
        // create lead in full lead
        this.restangular.all('/toptarjetas/v2/lead')
        .post(lead).then(function(response) {
            // resolve promise 
            deferred.resolve(response.plain());
        });
        //return the promise
        return promise;
    },

    createFullLeadToptarjetasFlink: function(lead) {
        var deferred = this.q.defer();
        var promise = deferred.promise;
        // create lead in full lead
        this.restangular.all('/toptarjetas/v2/flink')
        .post(lead).then(function(response) {
            // resolve promise 
            deferred.resolve(response.plain());
        });
        //return the promise
        return promise;
    },


    updateLead: function (lead) {
        var deferred = this.q.defer();
        var promise = deferred.promise;
        // update shortLead to fullLead
        this.restangular.all('/toptarjetas/v2/lead/'+lead.id)
            .customPUT({
                id: lead.id,
                email: lead.email,
                mobile_number: lead.mobile_number,
                first_name: lead.first_name,
                last_name: lead.last_name,
                second_last_name: lead.second_last_name,
                birthdate: lead.birthdate,
                accept_terms: lead.accept_terms,
                accept_privacy: lead.accept_privacy,
                monthly_salary: lead.monthly_salary
            }).then(function (response) {
                // resolve the promise
                deferred.resolve(response.plain());
            });

        //return the promise
        return promise;
    },

    updateLeadChild: function (lead) {
        var deferred = this.q.defer();
        var promise = deferred.promise;
        // update shortLead to fullLead
        this.restangular.all('/toptarjetas/v2/lead/child/'+lead.id)
            .customPUT({
                xsell: lead.xsell,
                click: lead.click,
            }).then(function (response) {
                // resolve the promise
                deferred.resolve(response.plain());
            });

        //return the promise
        return promise;
    },

    updateLeadChildXsell: function (lead) {
        var deferred = this.q.defer();
        var promise = deferred.promise;
        // update shortLead to fullLead
        this.restangular.all('/toptarjetas/v2/lead/child/'+lead.id)
            .customPUT({
                xsell: lead.xsell,
            }).then(function (response) {
                // resolve the promise
                deferred.resolve(response.plain());
            });

        //return the promise
        return promise;
    },

    getRecommendedProducts: function (monthly_income, interest, provider) {
        var deferred = this.q.defer();
        var promise = deferred.promise;
        this.restangular.one('toptarjetas', '')
            .one('product', monthly_income)
            .one('', interest)
            .one('', provider)
            .get()
            .then(function (response) {
                // resolve the promise
                deferred.resolve(response.plain());
            });

        //return the promise
        return promise;
    },
    getProductXSell: function(product) {
        var deferred = this.q.defer();
        var promise = deferred.promise;
        this.restangular.all('toptarjetas/v2/product/'+product)
        .getList()
        .then(function (response) {
            // resolve the promise
            deferred.resolve(response.plain());
        })
        .catch(function(err){
            console.log('ERR::', err);
        });

        //return the promise
        return promise;
    },

    updateToFullLead: function (lead) {
        var deferred = this.q.defer();
        var promise = deferred.promise;

        // create short lead
        this.restangular.all('/toptarjetas/lead/complete')
            .post({
                id: lead.id,
                name: lead.name,
                lastname: lead.lastname,
                status: 'COMPLETE'
            }).then(function (response) {
                // resolve the promise
                deferred.resolve(response.plain());
            });

        //return the promise
        return promise;
    },

    getIpClient: function (lead) {
        var deferred = this.q.defer();
        var promise = deferred.promise;

        // create short lead
        this.http.get("https://api.ipify.org?format=json", { timeout: 2000 }).then(function (response) {
            // resolve the promise
            deferred.resolve(response);
        }, function errorCallback(response) {
            deferred.resolve({ data: { ip: 'info_ip_error' } });
        });

        //return the promise
        return promise;
    },


    validateDuplicatedLead: function (email, phone) {
        var deferred = this.q.defer();
        var promise = deferred.promise;

        this.restangular.one('lead/v2', email).one('', phone)
            .get().then(function (response) {
                // resolve the promise
                deferred.resolve(response.plain());
            });

        // return the promise 
        return promise;
    },

    leadApply: function (lead) {
        var deferred = this.q.defer();
        var promise = deferred.promise;

        // create lead in full lead
        this.restangular.all('/toptarjetas/v2/lead/'+lead.id+'/apply')
        .post(lead).then(function(response) {
            // resolve promise 
            deferred.resolve(response.plain());
        });
        //return the promise
        return promise;
    },

    leadApplyBanamex: function (lead) {
        var deferred = this.q.defer();
        var promise = deferred.promise;

        // create lead in full lead
        this.restangular.all('/toptarjetas/v2/lead/'+lead.id+'/applybanamex')
        .post(lead).then(function(response) {
            // resolve promise 
            deferred.resolve(response.plain());
        });
        //return the promise
        return promise;
    },

    checkBlacklist: function (number){
        var deferred = this.q.defer();
        var promise = deferred.promise;
    
        // Get the localities using the Zip Code
        this.restangular.one('/blacklist/v2/' + number + "?lender=amex").get().then(
          function (response) {
            deferred.resolve(response);
          }, function (response) {
            deferred.resolve(response);
          }
        );
    
        // return the promise
        return promise;
    },

    getSetting: function(params){
        var deferred = this.q.defer();
        var promise = deferred.promise;

         // Get key
         this.restangular.one(`toptarjetas/v2/setting?${params}`).get().then(
            function (response) {
              deferred.resolve(response);
            }, function (response) {
              deferred.resolve(response);
            }
          );
        //return the promise
        return promise;
    },

    sendSms: function(params){
        var deferred = this.q.defer();
        var promise = deferred.promise;
        // send sms
        this.restangular.all('/toptarjetas/v2/sendSms')
        .post(params).then(function(response) {
            // resolve promise 
            deferred.resolve(response.plain());
        });
        //return the promise
        return promise;
    },
    sendEmail: function(params){
        var deferred = this.q.defer();
        var promise = deferred.promise;
        // send sms
        this.restangular.all('/toptarjetas/v2/sendEmail')
        .post(params).then(function(response) {
            // resolve promise 
            deferred.resolve(response.plain());
        });
        //return the promise
        return promise;
    },

    getLink: function(cid, afid, sid, params_extend, product_name){ 
        var deferred = this.q.defer();
        var promise = deferred.promise;
        var pn = product_name != undefined && product_name != '' ? product_name : 'TOPTARJETAS';
        let arrayParams = _.remove(_.map(params_extend, function(value, key){
            if(value != ''){
                return `${key}=${value}`
            }
        }), function(o) { return o !== undefined });
        let params = '';
        if(arrayParams.length > 0){
            params = _.join(arrayParams, '&');
        }
        var utm_sid = sid == "" || sid == undefined ? 'unassigned' : sid;
        const url = params != '' ? 'linktrust/v2/redirect?cid=' + cid + '&afid=' + afid + '&sid=' + utm_sid + '&product=' + pn + '&' + params : 'linktrust/v2/redirect?cid=' + cid + '&afid=' + afid + '&sid=' + utm_sid + '&product=' + pn;
        // Get key
         this.restangular.one(url).get().then(
            function (response) {
              deferred.resolve(response);
            }, function (response) {
              deferred.resolve(response);
            }
          );
        //return the promise
        return promise;
    },

    getLinkFlink: function(cid, afid, sid, params_extend, sinSid){ 
        var deferred = this.q.defer();
        var promise = deferred.promise;
        var sSid = sinSid ? sid : sid;
        var product = params_extend.affRefCidOrigin == '435257' ? "TOPTARJETASAFF" : "TOPTARJETASINT";
        if(cid == '435681')
            product = 'TOPTARJETAS';
        let arrayParams = _.remove(_.map(params_extend, function(value, key){
            if(value != ''){
                return `${key}=${value}`
            }
        }), function(o) { return o !== undefined });
        let params = '';
        if(arrayParams.length > 0){
            params = _.join(arrayParams, '&');
        }
        const url = params != '' ? 'linktrust/v2/redirect?cid=' + cid + '&afid=' + afid + '&sid=' + sSid + '&product=' + product + '&' + params : 'linktrust/v2/redirect?cid=' + cid + '&afid=' + afid + '&sid=' + sid + '&product=TOPTARJETAS';
        // Get key
         this.restangular.one(url).get().then(
            function (response) {
              deferred.resolve(response);
            }, function (response) {
              deferred.resolve(response);
            }
          );
        //return the promise
        return promise;
    },
    getLinkCoru: function(id){
        var deferred = this.q.defer();
        var promise = deferred.promise;

         // Get key
         this.restangular.one(`toptarjetas/v2/coru/${id}`).get().then(
            function (response) {
              deferred.resolve(response);
            }, function (response) {
              deferred.resolve(response);
            }
          );
        //return the promise
        return promise;
    },
    getAffilate: function(afid){
        var deferred = this.q.defer();
        var promise = deferred.promise;

         // Get key
         this.restangular.one(`Affiliate?afid=${afid}`).get().then(
            function (response) {
              deferred.resolve(response);
            }, function (response) {
              deferred.resolve(response);
            }
          );
        //return the promise
        return promise;
    },
    saveLeadAzteca: function(email, phone){
        var deferred = this.q.defer();
        var promise = deferred.promise;
        // create lead in full lead
        this.restangular.all('/azteca/email')
        .post({ email, mobile_number: phone, product: 'TOPTARJETAS' }).then(function(response) {
            // resolve promise 
            deferred.resolve(response.plain());
        });
        //return the promise
        return promise;
    }

}