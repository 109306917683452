angular
    .module('app')
    .component('modalFormAmexComponent', {
        templateUrl: 'app/components/amex/modalFormAmex/modalFormAmex.component.html',
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        },
        controller: ModalFormAmex,
    });

/** @ngInject */
function ModalFormAmex(wizardService, $cookies, $timeout, EnvironmentConfig, $window) {
    // init variables
    this.EnvironmentConfig = EnvironmentConfig;
    this.wizardService = wizardService;
    this.cookies = $cookies;
    this.timeout = $timeout;
    this.window = $window;
    // patterns for validates inputs text
    this.phonePattern = "\\d+";
    this.emailPattern = /^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i;
    // show email invalid message
    this.invalidEmail = false;
    // show ajax spinner
    this.showSpinner = false;
    // lead data
    this.email = "";
    this.phone = "";
    this.campaign_params = JSON.parse(this.cookies.get('campaign_params'));
    this.entry_url = this.cookies.get('entry_url');
};


ModalFormAmex.prototype = {

    // this function executes when modal-dialog opens
    $onInit: function() {
        this.product = this.resolve.product;
    },

    // this function executes when the user clicks ok butto
    ok: function() {
        var self = this;

        // validate duplicated lead
        self.wizardService.validateDuplicatedLead(self.email, self.phone).then(function(dataDuplicated) {
            self.showSpinner = true;
            // get client ip
            self.wizardService.getIpClient().then(function(clientData) {
                // calls leadService for validate email and create the lead
                self.wizardService.createFullLead({
                    email: self.email,
                    mobile_number: self.phone,
                    utm_source: self.campaign_params.utm_source,
                    entry_url: self.entry_url,
                    chosen_product: self.product.id,
                    status: 'COMPLETE',
                    user_ip: clientData.data.ip,
                    device_details: JSON.stringify(getDetails()),
                    sid: self.campaign_params.SID,
                    sent_to_bnmx: false,
                    loan: 0,
                    return_days:80,
                    application_path: 'TOP_TARJETAS'
                }).then(function(data) {
                    if (dataDuplicated.duplicated) {
                        // step duplicated convertion
                        self.trackPixel = self.EnvironmentConfig.site.duplicated_lead_pixel_amex +
                            data.id + "_" + data.email;
                    } else {
                        // new convertion 
                        self.trackPixel = self.EnvironmentConfig.site.no_duplicated_lead_pixel_amex +
                            data.id + "_" + data.email;
                    }
                    // redirect to client landing              
                    self.timeout(function() {
                        const utm = self.campaign_params.utm_source;
                        var utm_source_ojo7 = [ 422827, 422168, 418761, 425145, 421382, 424505, 427794, 427795, 425060, 427652, 421390, 423011, 423443, 427868, 427796, 422498, 427793, 427828, 421388, 421581, 425074, 424713, 426251 ]
                        const find = _.find(utm_source_ojo7, function(value) { return value == utm; });
                        switch (self.product.friendly_name) {
                            case 'goldCard':
                                if (utm == 424881){ //PINO
                                    var url = vsprintf(self.product.pixel_url, [
                                        2334546,
                                        self.campaign_params.utm_source,
                                        self.campaign_params.SID ? 'toptarjetas_cid420775_' + self.campaign_params.SID : 'toptarjetas_cid420775'
                                    ]);
                                    self.window.location.href = url;
                                }else if(utm == 422168){ // MAIL
                                    var url = vsprintf(self.product.pixel_url, [
                                        2334543,
                                        self.campaign_params.utm_source,
                                        self.campaign_params.SID ? 'toptarjetas_cid420775_' + self.campaign_params.SID : 'toptarjetas_cid420775'
                                    ]);
                                    self.window.location.href = url;
                                }else if(find == undefined){ // AFILIADOS EXTERNOS
                                    var url = vsprintf(self.product.pixel_url, [
                                        2334545,
                                        self.campaign_params.utm_source,
                                        self.campaign_params.SID ? 'toptarjetas_cid420775_' + self.campaign_params.SID : 'toptarjetas_cid420775'
                                    ]);
                                    self.window.location.href = url;
                                }else{ // AFFILIADOS INTERNOS OJO7
                                    var url = vsprintf(self.product.pixel_url, [
                                        2334544,
                                        self.campaign_params.utm_source,
                                        self.campaign_params.SID ? 'toptarjetas_cid420775_' + self.campaign_params.SID : 'toptarjetas_cid420775'
                                    ]);
                                    self.window.location.href = url;
                                }
                                break;
                            case 'eliteCard':
                                this.window.ga('send', 'event', 'amex', 'click', 'EliteCard');
                                self.wizardService.getLink('426068', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID)
                                .then(function(result){
                                    self.window.location.href = result.detail;
                                })
                                break;
                            case 'greenCard':
                                if (utm == 424881){ //PINO
                                    var url = vsprintf(self.product.pixel_url, [
                                        2325878,
                                        self.campaign_params.utm_source,
                                        self.campaign_params.SID ? 'toptarjetas_cid420775_' + self.campaign_params.SID : 'toptarjetas_cid420775'
                                    ]);
                                    self.window.location.href = url;
                                }else if(utm == 422168){ // MAIL
                                    var url = vsprintf(self.product.pixel_url, [
                                        2325793,
                                        self.campaign_params.utm_source,
                                        self.campaign_params.SID ? 'toptarjetas_cid420775_' + self.campaign_params.SID : 'toptarjetas_cid420775'
                                    ]);
                                    self.window.location.href = url;
                                }else if(find == undefined){ // AFILIADOS EXTERNOS
                                    var url = vsprintf(self.product.pixel_url, [
                                        2325875,
                                        self.campaign_params.utm_source,
                                        self.campaign_params.SID ? 'toptarjetas_cid420775_' + self.campaign_params.SID : 'toptarjetas_cid420775'
                                    ]);
                                    self.window.location.href = url;
                                }else{ // AFFILIADOS INTERNOS OJO7
                                    var url = vsprintf(self.product.pixel_url, [
                                        2325797,
                                        self.campaign_params.utm_source,
                                        self.campaign_params.SID ? 'toptarjetas_cid420775_' + self.campaign_params.SID : 'toptarjetas_cid420775'
                                    ]);
                                    self.window.location.href = url;
                                }
                                break;
                            case 'platinumCard':
                                if (utm == 424881){ //PINO
                                    var url = vsprintf(self.product.pixel_url, [
                                        2325879,
                                        self.campaign_params.utm_source,
                                        self.campaign_params.SID ? 'toptarjetas_cid420775_' + self.campaign_params.SID : 'toptarjetas_cid420775'
                                    ]);
                                    self.window.location.href = url;
                                }else if(utm == 422168){ // MAIL
                                    var url = vsprintf(self.product.pixel_url, [
                                        2325794,
                                        self.campaign_params.utm_source,
                                        self.campaign_params.SID ? 'toptarjetas_cid420775_' + self.campaign_params.SID : 'toptarjetas_cid420775'
                                    ]);
                                    self.window.location.href = url;
                                }else if(find == undefined){ // AFILIADOS EXTERNOS
                                    var url = vsprintf(self.product.pixel_url, [
                                        2325876,
                                        self.campaign_params.utm_source,
                                        self.campaign_params.SID ? 'toptarjetas_cid420775_' + self.campaign_params.SID : 'toptarjetas_cid420775'
                                    ]);
                                    self.window.location.href = url;
                                }else{ // AFFILIADOS INTERNOS OJO7
                                    var url = vsprintf(self.product.pixel_url, [
                                        2325796,
                                        self.campaign_params.utm_source,
                                        self.campaign_params.SID ? 'toptarjetas_cid420775_' + self.campaign_params.SID : 'toptarjetas_cid420775'
                                    ]);
                                    self.window.location.href = url;
                                }
                                break;
                        }
                    }, 1000);
                });
            });
        });

    },

    // this function executes when the user clicks cancel button
    cancel: function() {
        this.dismiss({ $value: 'cancel' });
    },

    // this function restrict only numbers in input text
    onlyNumers: function(event) {
        const pattern = /[0-9\+\-\ ]/;
        var inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    },
};