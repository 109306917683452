angular
  .module('app')
  .component('resultComponent', {
    // templateUrl: 'app/components/wizard/result.component.html',
    templateUrl: 'app/components/wizard/result.component.html',
    controller: Result
  });

/** @ngInject */
function Result($stateParams, $uibModal, $rootScope, $state, $window, wizardService, $cookies) {
    this.window = $window;
    this.wizardService = wizardService;
    this.rs = $rootScope;
    this.wizardService = wizardService;
    this.state = $state;
    this.cookies = $cookies;
    this.showSpinner = false;
    this.campaign_params = JSON.parse(this.cookies.get('campaign_params'));
    this.sp = $stateParams;
    // this.uibModal = $uibModal; 
    // get products from the stateParams
    let apply = undefined;
    if(!this.cookies.get('apply'))
        this.state.go('app');
    else
        apply = JSON.parse(this.cookies.get('apply'));
    
    if(!apply)
        this.state.go('app');
    this.products = apply.products; 
    this.lender = apply.lender;
    this.name = this.products[0].name;
    this.rs.lead.xsell = this.lender.toUpperCase();
    this.wizardService.updateLeadChildXsell(this.rs.lead)
    .then(function(upLead){
        console.log(upLead);
    })
    if(this.rs.params_extend.t_num == '')
        this.rs.params_extend.t_num = this.rs.lead.mobile_number
    
    if(this.rs.params_extend.t_email == '')
        this.rs.params_extend.t_email = this.rs.lead.email
    
    if(!this.rs.lead.id){ 
        this.state.go('app'); 
    } 
    this.window = $window;
    this.window.fbq('track', 'ViewContent');
    this.window.dataLayer.push({
        'event': 'wizard',
        'step': 6
    });

    this.textBtn = '';

    if( this.lender == 'platacard' || this.lender == 'hsbc' || this.lender == 'coppel_dicatmen' || this.lender == 'azteca' || this.lender == 'klar' || this.lender == 'kapital' || this.lender == 'storicard' || this.lender == 'bankaya' || this.lender == 'bankaya_fondeo' || this.lender == 'rappicard' || this.lender == 'uala' || this.lender == 'fondeadora' || this.lender == 'vexi' || this.lender == 'nubank' || this.lender == 'coru'){
        this.textBtn = "¡Solicitar tarjeta ahora!";
    }else if(this.lender == 'kimbi' || this.lender == 'kueski' || this.lender == 'kueski_pino' || this.lender == 'dineria' || this.lender == 'moneyman' || this.lender == 'crezu' || this.lender == 'pezetita' || 'credito365'){
        this.textBtn = "¡Solicitar préstamo ahora!";
    }else if(this.lender == 'flink'){
        this.textBtn = "¡Descargar App ahora!";
    }else if(this.lender == 'coppel_dictamen' || this.lender == 'amigoloans'){
        this.textBtn = "¡Solicitar crédito ahora!";
    }else if(this.lender == 'baz' || this.lender == 'moneymanins' || this.lender == 'fidea'){
        this.textBtn = "¡Solicitar ahora!";
    }else{
        this.textBtn = "¡Solicitar tu préstamo y tarjeta ahora!";
    }
} 
 
Result.prototype = { 
 
    ok: function (product) {
        var self = this;
        self.showSpinner = true;
        // var utm_source_ojo7 = [ 422827, 422168, 418761, 425145, 421382, 424505, 427794, 427795, 425060, 427652, 421390, 423011, 423443, 427868, 427796, 422498, 427793, 427828, 421388, 421581, 425074, 424713, 426251 ]
        // const utm = self.campaign_params.utm_source;
        const deviceDetails = getDetails();
        self.rs.lead.click = 1;
        self.cookies.remove('apply');
        switch (product.provider) {
            case 'KIMBI':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'KIMBI'
                });
                self.textBtn = '¡Actualizando datos!';
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.textBtn = '¡Obteniendo redirección!';
                    self.wizardService.getLink('467190', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.textBtn = '¡Listo!';
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'CREDITO365':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'CREDITO365'
                });
                self.textBtn = '¡Actualizando datos!';
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.textBtn = '¡Obteniendo redirección!';
                    self.wizardService.getLink('466735', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.textBtn = '¡Listo!';
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'PLATACARD':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'PLATACARD'
                });
                self.textBtn = '¡Actualizando datos!';
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.textBtn = '¡Obteniendo redirección!';
                    self.wizardService.getLink('457221', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.textBtn = '¡Listo!';
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'COPPEL':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'COPPEL'
                });
                self.textBtn = '¡Solicitar tu préstamo y tarjeta ahora!';
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('440050', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.textBtn = '¡Listo!';
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'AMEX':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'AMEX'
                });
                self.textBtn = '¡Actualizando datos!';
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.textBtn = '¡Obteniendo redirección!';
                    self.wizardService.getLink('466232', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.textBtn = '¡Listo!';
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'HSBC_STILO':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'HSBC_STILO'
                });
                self.textBtn = '¡Actualizando datos!';
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.textBtn = '¡Obteniendo redirección!';
                    self.wizardService.getLink('466528', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.textBtn = '¡Listo!';
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'HSBC':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'HSBC'
                });
                self.textBtn = '¡Actualizando datos!';
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.textBtn = '¡Obteniendo redirección!';
                    self.wizardService.getLink('465903', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.textBtn = '¡Listo!';
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'COPPEL_DICTAMEN':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'COPPEL_DICTAMEN'
                });
                self.textBtn = '¡Solicitar tu préstamo y tarjeta ahora!';
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('451529', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'AZTECA':
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    if(deviceDetails.OS == 'iOS'){
                        self.window.dataLayer.push({
                            'event': 'xsell_option',
                            'lender': 'AZTECA_IOS'
                        });
                        self.wizardService.getLink('430147', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                        .then(function(result){
                            self.window.location.href = result.detail;
                        })
                    }else{
                        self.window.dataLayer.push({
                            'event': 'xsell_option',
                            'lender': 'AZTECA_ANDROID'
                        });
                        self.wizardService.getLink('430148', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                        .then(function(result){
                            self.window.location.href = result.detail;
                        })
                    }
                })
                break;
            case 'KUESKI':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'KUESKI'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.window.obApi('track', 'Tarjetas Click en Lender UTM');
                    self.wizardService.getLink('402954', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                
                break; 
            case 'KUESKI_PINO':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'KUESKI_PINO'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.window.obApi('track', 'Tarjetas Click en Lender UTM');
                    self.wizardService.getLink('424712', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break; 
            case 'DINERIA':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'DINERIA'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('436836', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'CORU':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'CORU'
                });
                self.wizardService.getLink('440385', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                .then(function(result){
                    self.window.location.href = result.detail;
                })
                break; 
            case 'KLAR':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'KLAR'
                });
                self.wizardService.getLink('430133', self.campaign_params.utm_source, self.campaign_params.SID, self.rs.params_extend)
                .then(function(result){
                    self.window.location.href = result.detail;
                })
                break;
            case 'FLINK':
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    if(deviceDetails.OS == 'iOS'){
                        self.window.dataLayer.push({
                            'event': 'xsell_option',
                            'lender': 'FLINK_IOS'
                        });
                        self.wizardService.getLink('435158', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                        .then(function(result){
                            self.window.location.href = result.detail;
                        })
                    }else{
                        self.window.dataLayer.push({
                            'event': 'xsell_option',
                            'lender': 'FLINK_ANDROID'
                        });
                        self.wizardService.getLink('435157', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                        .then(function(result){
                            self.window.location.href = result.detail;
                        })
                    }
                })
                break;
            case 'KAPITAL':
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    if(deviceDetails.OS == 'iOS'){
                        self.window.dataLayer.push({
                            'event': 'xsell_option',
                            'lender': 'KAPITAL_IOS'
                        });
                        self.wizardService.getLink('435681', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                        .then(function(result){
                            self.window.location.href = result.detail;
                        })
                    }else{
                        self.window.dataLayer.push({
                            'event': 'xsell_option',
                            'lender': 'KAPITAL_ANDROID'
                        });
                        self.wizardService.getLink('435679', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                        .then(function(result){
                            self.window.location.href = result.detail;
                        })
                    }
                })
                break;
                case 'BKAPITAL':
                    self.rs.lead.xsell = product.name.toUpperCase();
                    self.wizardService.updateLeadChild(self.rs.lead)
                    .then(upd => {
                        if(deviceDetails.OS == 'iOS'){
                            self.window.dataLayer.push({
                                'event': 'xsell_option',
                                'lender': 'BKAPITAL_IOS'
                            });
                            self.wizardService.getLink('435681', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                            .then(function(result){
                                self.window.location.href = result.detail;
                            })
                        }else{
                            self.window.dataLayer.push({
                                'event': 'xsell_option',
                                'lender': 'BKAPITAL_ANDROID'
                            });
                            self.wizardService.getLink('435679', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                            .then(function(result){
                                self.window.location.href = result.detail;
                            })
                        }
                    })
                    break;
            case 'MONEYMAN':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'MONEYMAN'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('419168', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'CREZU':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'CREZU'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('434566', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'PEZETITA':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'PEZETITA'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('434524', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'ANDACREDITO':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'ANDACREDITO'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('436758', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'STORICARD':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'STORICARD'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('435519', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'AMIGOLOANS':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'AMIGOLOANS'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('405537', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'BANKAYA':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'BANKAYA'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('438087', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'BANKAYA_FONDEO':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'BANKAYA_FONDEO'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('453003', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'RAPPICARD':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'RAPPICARD'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('437128', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'UALA':
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    if(deviceDetails.OS == 'iOS'){
                        self.window.dataLayer.push({
                            'event': 'xsell_option',
                            'lender': 'UALA_IOS'
                        });
                        self.wizardService.getLink('440398', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                        .then(function(result){
                            self.window.location.href = result.detail;
                        })
                    }else{
                        self.window.dataLayer.push({
                            'event': 'xsell_option',
                            'lender': 'UALA_ANDROID'
                        });
                        self.wizardService.getLink('440397', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                        .then(function(result){
                            self.window.location.href = result.detail;
                        })
                    }
                })
                break;
            case 'FONDEADORA':
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    if(deviceDetails.OS == 'iOS'){
                        self.window.dataLayer.push({
                            'event': 'xsell_option',
                            'lender': 'FONDEADORA_IOS'
                        });
                        self.wizardService.getLink('440075', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                        .then(function(result){
                            self.window.location.href = result.detail;
                        })
                    }else{
                        self.window.dataLayer.push({
                            'event': 'xsell_option',
                            'lender': 'FONDEADORA_ANDROID'
                        });
                        self.wizardService.getLink('440076', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                        .then(function(result){
                            self.window.location.href = result.detail;
                        })
                    }
                })
                break;
            case 'VEXI':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'VEXI'
                });
                self.textBtn = '¡Actualizando datos!';
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.textBtn = '¡Obteniendo redirección!';
                    self.wizardService.getLink('437485', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.textBtn = '¡Listo!';
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'NUBANK':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'NUBANK'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('441257', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'RAPPIPAY':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'RAPPIPAY'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('441407', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'BAZ':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'BAZ'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('440703', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'MONEYMANINS':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'MONEYMANINS'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('443103', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            case 'FIDEA':
                self.window.dataLayer.push({
                    'event': 'xsell_option',
                    'lender': 'FIDEA'
                });
                self.wizardService.updateLeadChild(self.rs.lead)
                .then(upd => {
                    self.wizardService.getLink('443192', self.campaign_params.utm_source, self.campaign_params.SID == '' ? 'unassigned' :  self.campaign_params.SID, self.rs.params_extend)
                    .then(function(result){
                        self.window.location.href = result.detail;
                    })
                })
                break;
            default:
                self.state.go('thankyou', {
                    full_app: true,
                    lead_id: self.rs.lead.id,
                    lead_email: self.rs.lead.email
                });
                break;
        } 
    }
};