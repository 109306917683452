angular
    .module('app')
    .component('step1Component', {
        templateUrl: 'app/components/wizard/step1.component.html',
        controller: Step1
    });

/** @ngInject */
function Step1($stateParams, $rootScope, $state, wizardService, $cookies, $window, $location, $timeout) {
    this.rs = $rootScope;
    this.sp = $stateParams;
    this.state = $state;
    this.wizardService = wizardService;
    this.cookies = $cookies;
    this.invalidEmail = false;
    this.showSpinner = false;
    this.location = $location;
    // patterns for validates inputs text
    this.phonePattern = /^[1-9]\d*$/;
    this.emailPattern = /^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i;
    // get device details
    this.rs.lead.device_details = JSON.stringify(getDetails());
    this.rs.lead.user_agent = getDetails()['Full User Agent'];
    this.rs.lead.window_size = getDetails()['Screen Size'];
    this.rs.lead.is_mobile = getDetails()['Mobile'];
    this.rs.lead.entry_url = this.cookies.get('entry_url');
    this.window = $window;
    this.window.fbq('track', 'ViewContent');
    this.timeout = $timeout;
    this.main = this.sp.main;
    var self = this;
    this.rs.lead.entry_url = this.rs.lead.entry_url == '' ? this.window.location.href : this.rs.lead.entry_url;
    this.wizardService.getIpClient().then(function(clientData) {
        self.rs.lead.user_ip = clientData.data.ip;
    });
    this.window.dataLayer.push({
        'event': 'wizard',
        'step': 1
    });
    if(!this.main){
        if(this.window.location.search != ''){
            let utm_source = '';
            let SID = '';
            if(this.sp.utm_source === undefined && this.sp.gclid){
                SID = 'gclid';
                utm_source = '425145';
            }else{
                SID = this.sp.SID
                utm_source = this.sp.utm_source
            }
    
            // create campaign_params json for save it in the cookies for linktrust pixels
            this.campaign_params = {
                utm_source: this.validate_utm_source(utm_source) ? utm_source : '418761',
                sub_id: this.generate_sub_id(this.sp.utm_source, this.sp.sub_id),
                SID: SID ? SID : "unassigned",
                utm_medium: this.sp.utm_medium ? this.sp.utm_medium : "",
                utm_campaign: this.sp.utm_campaign ? this.sp.utm_campaign : "",
                utm_term: this.sp.utm_term ? this.sp.utm_term : "",
                utm_content: this.sp.utm_content ? this.sp.utm_content : "",
                utm_nooverride: this.sp.utm_nooverride ? this.sp.utm_nooverride : "",
                click_id: this.sp.click_id ? this.sp.click_id : "",
                force: this.sp.force ? this.sp.force : "",
                origin: this.sp.origin ? this.sp.origin : "",
                email_delivery_id: this.sp.email_delivery_id ? this.sp.email_delivery_id  : "",
                email_commission_campaign: this.sp.email_commission_campaign ? this.sp.email_commission_campaign : "",
                affRefCidOrigin: this.sp.affRefCidOrigin ? this.sp.affRefCidOrigin : "",
                gclid: this.sp.gclid ? this.sp.gclid : "",
                t_num: this.sp.t_num ? this.sp.t_num : "",
                t_id: this.sp.t_id ? this.sp.t_id : "",
                t_email: this.sp.t_email ? this.sp.t_email : "",
                fbclid: this.sp.fbclid ? this.sp.fbclid : "",
                ttclid: this.sp.ttclid ? this.sp.ttclid : ""
            }
    
            this.params_extend = {
                t_num: this.sp.t_num ? this.sp.t_num : "",
                t_id: this.sp.t_id ? this.sp.t_id : "",
                t_email: this.sp.t_email ? this.sp.t_email : "",
                origin: this.sp.origin ? this.sp.origin : "",
                email_delivery_id: this.campaign_params.email_delivery_id ? this.campaign_params.email_delivery_id  : "",
                email_commission_campaign: this.campaign_params.email_commission_campaign ? this.campaign_params.email_commission_campaign : "",
                AffiliateReferenceId: this.campaign_params.sub_id,
                affRefCidOrigin: this.campaign_params.affRefCidOrigin ? this.campaign_params.affRefCidOrigin : "",
                id_bridge: this.sp.id_bridge ? this.sp.id_bridge : "",
                fbclid: this.sp.fbclid ? this.sp.fbclid : "",
                ttclid: this.sp.ttclid ? this.sp.ttclid : ""
            }
            const cp = this.campaign_params;
            const wp = _.find(_.values(cp), function(o){ return (o != '' && o != '418761')});
            this.rs.withParams = wp === undefined ? false : true;
            this.rs.params_extend = this.params_extend;
            
            // remove the campaign parameters if it is in cookies
            // if (!this.cookies.get('campaign_params'))
            //   this.cookies.remove('campaign_params');
    
            // set a new cookie
            this.cookies.put('campaign_params', JSON.stringify(this.campaign_params), { secure: false, samesite: 'strict'});
            if (!this.cookies.entry_url)
                this.cookies.put('entry_url', this.window.location.href, { secure: false, samesite: 'strict'});
            var self = this;
            if(this.rs.params_extend.t_num != '' && this.rs.params_extend.t_email != '' && this.campaign_params.utm_source == '477596'){
                this.redirect = true;
                self.timeout(function(){ self.clickNext() }, 1000)
            }
        }else{
            if(!this.cookies.get('campaign_params')){
                console.log("No tiene cookies");
                let utm_source = '';
                let SID = '';
                if(this.sp.utm_source === undefined && this.sp.gclid){
                    SID = 'gclid';
                    utm_source = '425145';
                }else{
                    SID = this.sp.SID
                    utm_source = this.sp.utm_source
                }
            
                // create campaign_params json for save it in the cookies for linktrust pixels
                this.campaign_params = {
                    utm_source: this.validate_utm_source(utm_source) ? utm_source : '418761',
                    sub_id: this.generate_sub_id(this.sp.utm_source, this.sp.sub_id),
                    SID: SID ? SID : "unassigned",
                    utm_medium: this.sp.utm_medium ? this.sp.utm_medium : "",
                    utm_campaign: this.sp.utm_campaign ? this.sp.utm_campaign : "",
                    utm_term: this.sp.utm_term ? this.sp.utm_term : "",
                    utm_content: this.sp.utm_content ? this.sp.utm_content : "",
                    utm_nooverride: this.sp.utm_nooverride ? this.sp.utm_nooverride : "",
                    click_id: this.sp.click_id ? this.sp.click_id : "",
                    force: this.sp.force ? this.sp.force : "",
                    origin: this.sp.origin ? this.sp.origin : "",
                    email_delivery_id: this.sp.email_delivery_id ? this.sp.email_delivery_id  : "",
                    email_commission_campaign: this.sp.email_commission_campaign ? this.sp.email_commission_campaign : "",
                    affRefCidOrigin: this.sp.affRefCidOrigin ? this.sp.affRefCidOrigin : "",
                    gclid: this.sp.gclid ? this.sp.gclid : "",
                    t_num: this.sp.t_num ? this.sp.t_num : "",
                    t_id: this.sp.t_id ? this.sp.t_id : "",
                    t_email: this.sp.t_email ? this.sp.t_email : "",
                    id_bridge: this.sp.id_bridge ? this.sp.id_bridge : "",
                    fbclid: this.sp.fbclid ? this.sp.fbclid : "",
                    ttclid: this.sp.ttclid ? this.sp.ttclid : ""
                }
            
                this.params_extend = {
                    t_num: this.sp.t_num ? this.sp.t_num : "",
                    t_id: this.sp.t_id ? this.sp.t_id : "",
                    t_email: this.sp.t_email ? this.sp.t_email : "",
                    origin: this.sp.origin ? this.sp.origin : "",
                    email_delivery_id: this.campaign_params.email_delivery_id ? this.campaign_params.email_delivery_id  : "",
                    email_commission_campaign: this.campaign_params.email_commission_campaign ? this.campaign_params.email_commission_campaign : "",
                    AffiliateReferenceId: this.campaign_params.sub_id,
                    affRefCidOrigin: this.campaign_params.affRefCidOrigin ? this.campaign_params.affRefCidOrigin : "",
                    id_bridge: this.sp.id_bridge ? this.sp.id_bridge : "",
                    gclid: this.sp.gclid ? this.sp.gclid : "",
                    fbclid: this.sp.fbclid ? this.sp.fbclid : "",
                    ttclid: this.sp.ttclid ? this.sp.ttclid : ""
                }
                const cp = this.campaign_params;
                const wp = _.find(_.values(cp), function(o){ return (o != '' && o != '418761')});
                this.rs.withParams = wp === undefined ? false : true;
                this.rs.params_extend = this.params_extend;
                
                // remove the campaign parameters if it is in cookies
                // if (!this.cookies.get('campaign_params'))
                //   this.cookies.remove('campaign_params');
            
                // set a new cookie
                this.cookies.put('campaign_params', JSON.stringify(this.campaign_params), { secure: false, samesite: 'strict'});
                if (!this.cookies.entry_url)
                    this.cookies.put('entry_url', this.window.location.href, { secure: false, samesite: 'strict'});
                var self = this;
                if(this.rs.params_extend.t_num != '' && this.rs.params_extend.t_email != '' && this.campaign_params.utm_source == '477596'){
                    this.redirect = true;
                    self.timeout(function(){ self.clickNext() }, 1000)
                }
            }else{
                var self = this;
                this.campaign_params = JSON.parse(this.cookies.get('campaign_params'));
                this.params_extend = {
                    t_num: this.campaign_params.t_num ? this.campaign_params.t_num : "",
                    t_id: this.campaign_params.t_id ? this.campaign_params.t_id : "",
                    t_email: this.campaign_params.t_email ? this.campaign_params.t_email : "",
                    origin: this.campaign_params.origin ? this.campaign_params.origin : "",
                    email_delivery_id: this.campaign_params.email_delivery_id ? this.campaign_params.email_delivery_id  : "",
                    email_commission_campaign: this.campaign_params.email_commission_campaign ? this.campaign_params.email_commission_campaign : "",
                    AffiliateReferenceId: this.campaign_params.sub_id,
                    affRefCidOrigin: this.campaign_params.affRefCidOrigin ? this.campaign_params.affRefCidOrigin : "",
                    id_bridge: this.campaign_params.id_bridge ? this.campaign_params.id_bridge : "",
                    gclid: this.campaign_params.gclid ? this.campaign_params.gclid : "",
                    fbclid: this.campaign_params.fbclid ? this.campaign_params.fbclid : "",
                    ttclid: this.campaign_params.ttclid ? this.campaign_params.ttclid : ""
                }
                const cp = this.campaign_params;
                const wp = _.find(_.values(cp), function(o){ return (o != '' && o != '418761')});
                this.rs.withParams = wp === undefined ? false : true;
                this.rs.params_extend = this.params_extend;
                if(this.rs.params_extend.t_num != '' && this.rs.params_extend.t_email != '' && this.campaign_params.utm_source == '477596'){
                    this.redirect = true;
                    self.timeout(function(){ self.clickNext() }, 1000)
                }
            }
        }
    }
}

Step1.prototype = {
    // this function restrict only numbers in input text
    onlyNumers: function(event) {
        const pattern = /^[1-9]\d*$/;
        var inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar) && inputChar[0] != '0') {
            // invalid character, prevent input
            event.preventDefault();
        }
    },

    changeToStep2: function() {
        // redefine this context
        var self = this;
        self.rs.lead.has_credit = true;
        self.rs.lead.good_payer = false;
        // validate if the lead has already sent a request to validate mail
        if (self.rs.lead.id) {
            delete self.rs.lead.id;
            delete self.rs.lead.status;
        } 
        //else {

            this.campaign_params = JSON.parse(this.cookies.get('campaign_params'));

            // add utm_source and SID to lead main object
            this.rs.lead.utm_source = this.campaign_params.utm_source;
            this.rs.lead.sid = this.campaign_params.SID;
            this.rs.lead.sub_id = this.campaign_params.sub_id;
            this.rs.force = this.campaign_params.force;

            // show ajax spinner
            self.showSpinner = true;
            self.rs.lead.application_path = 'TOP_TARJETAS';
            self.rs.lead.loan = 0;
            self.rs.lead.return_days = 80;
            self.rs.lead.country = 'MX';
            self.wizardService.createFullLead(self.rs.lead).then(function(createdLead) {
                // save lead id to update it later 
                self.rs.lead.id = createdLead.id;
                // var absUrl = self.location.absUrl();
                // self.window.gtag_report_conversion(absUrl)
                self.window.dataLayer.push({
                    'event': 'lead_general',
                    'id_lead': createdLead.id,
                    'mobile_number': self.rs.lead.mobile_number,
                    'email': self.rs.lead.email,
                    'sub_id': self.rs.lead.sub_id
                });
                if (createdLead.duplicated) {
                    self.window.dataLayer.push({
                        'event': 'lead_duplicated',
                        'id_lead': createdLead.id,
                        'mobile_number': self.rs.lead.mobile_number,
                        'email': self.rs.lead.email,
                        'sub_id': self.rs.lead.sub_id
                    });
                    self.rs.duplicated = true;
                } else {
                    self.window.dataLayer.push({
                        'event': 'lead_new',
                        'id_lead': createdLead.id,
                        'mobile_number': self.rs.lead.mobile_number,
                        'email': self.rs.lead.email,
                        'sub_id': self.rs.lead.sub_id
                    });
                    // show normal pixel
                    self.window.obApi('track', 'Tarjetas Lead Unico');
                };
                self.timeout(function(){ self.state.go('step6'); }, 1000)   
            })
        //}
    },
    isInteger: function (data) {
        try {
          if (typeof data === 'number') {
            var remainder = data % 1
            if (remainder === 0) {
              return true
            }
          }
          return false
        } catch (err) {
          return false
        }
    },
    
    validate_utm_source: function (utm_source) {
        // validates if utm_source is integer
        if (!this.isInteger(utm_source * 1)
          || utm_source * 1 < 418761) {
          // set utm_source for organic origin (does not belong to a valid campaign )
          return false;
        }
        return true;
    },
    
    generate_sub_id: function (utm_source, sub_id) {
        // if utm_source isn't valid and sub_id is empty the sub_id becomes
        // in the original utm_source
        if (!this.validate_utm_source(utm_source)) {
          // utm_source no valid, campaign no valid
          if (sub_id == '' || typeof sub_id === 'undefined') {
            // if sub_id empty or undefined return utm_source
            if (utm_source == '' || typeof utm_source === 'undefined') {
              return '';
            }
            return utm_source;
          } else {
            // if sub_id is full return sub_id
            return sub_id;
          }
        } else {
          // utm_source valid
          if (sub_id == '' || typeof sub_id === 'undefined') {
            // if sub_id empty or undefined return empty string
            return "";
          } else {
            // if sub_id is full return sub_id
            return sub_id;
          }
        }
    }
};