angular
    .module('app')
    .component('step5Component', {
        templateUrl: 'app/components/wizard/step5.component.html',
        controller: Step5
    });

/** @ngInject */
function Step5($rootScope, wizardService, $state, $window, $scope, $cookies) {
    this.rs = $rootScope;
    this.cookies = $cookies;
    this.message = '';
    this.state = $state;
    this.showSpinner = false;
    this.wizardService = wizardService;
    this.window = $window;
    this.day = '';
    this.month = '';
    this.year = '';
    if (!this.rs.lead.id) {
        this.state.go('app');
    }
    this.window.dataLayer.push({
        'event': 'wizard',
        'step': 5
    });
    this.window.fbq('track', 'ViewContent');
    let currentDate = new Date();
    let years = [];
    let minYear = currentDate.getFullYear() - 70; 
    this.mYear = minYear;
    for (let i = 2003; i >= minYear; i--) {
        years.push(i);
    }
    $scope.years = years;
}

Step5.prototype = {
    validateInfo: function() {
        var self = this;

        if (self.message) {
            self.message = '';
        }
        // validate all wizard fields
        if (self.rs.lead.email == undefined ||
            self.rs.lead.email == '' ||
            self.rs.lead.mobile_number == undefined ||
            self.rs.lead.mobile_number == '' ||
            self.rs.lead.monthly_salary == undefined ||
            self.rs.lead.monthly_salary == '' ||
            self.rs.lead.first_name == undefined ||
            self.rs.lead.first_name == '' ||
            self.year == '') {
            self.message = ' Favor de llenar la informaci&oacute;n solicitada anteriormente<br>'
            return;
        } else {
            // if(parseInt(self.year) < parseInt(self.mYear)){
            //     if(parseInt(self.year) >= 1900){
            //         self.rs.lead.birthdate = new Date('2000', self.month - 1, self.day);
            //     }else if(parseInt(self.year) < 1900 && parseInt(self.year) > (parseInt(self.mYear)-100)){
            //         self.rs.lead.birthdate = new Date(self.year.replace('18','19'), self.month - 1, self.day);
            //     }else{
            //         self.rs.lead.birthdate = new Date(self.mYear, self.month - 1, self.day);
            //     }
            // }else{
            //     self.rs.lead.birthdate = new Date(self.year, self.month - 1, self.day);
            // }
            self.rs.lead.birthdate = new Date(self.year, 0, 1);
            self.showSpinner = true;
            const ms = self.rs.lead.monthly_salary;
            switch (ms) {
                case 'menos_de__4_000':
                    self.rs.lead.monthly_salary = 4000;
                    break;
                case 'más_de__4_000_y_menos_de__7_000':
                    self.rs.lead.monthly_salary = 5000;
                    break;
                case 'más_de__7_000_y_menos_de__15_000':
                    self.rs.lead.monthly_salary = 7000;
                    break;
                case 'más_de__15_000_y_menos_de__30_000':
                    self.rs.lead.monthly_salary = 15000;
                    break;
                case 'más_de__30_000_y_menos_de__50_000':
                    self.rs.lead.monthly_salary = 30000;
                    break;
                case 'más_de__50_000':
                    self.rs.lead.monthly_salary = 50000;
                    break;
            }
            self.rs.lead.createdAt = new Date();
            self.rs.lead.updatedAt = new Date();
            self.rs.lead.last_step = 4;
            const names = self.rs.lead.first_name.split(" ");
            self.rs.lead.first_name = names.length > 3 ? `${names[0].replace(/\d+/, "")} ${names[1].replace(/\d+/, "")}` : names[0].replace(/\d+/, "");
            self.rs.lead.last_name = names.length > 3 ? names[2].replace(/\d+/, "") : names.length < 2 ? "" : names[1].replace(/\d+/, "") ;
            self.rs.lead.second_last_name = names.length > 3 ? names[3].replace(/\d+/, "") : names.length == 3 ? names[2].replace(/\d+/, "") : names.length == 2 ? names[1].replace(/\d+/, "") : "";
            self.rs.lead.first_name = self.rs.lead.first_name.replace(/,/g, "");
            self.rs.lead.last_name = self.rs.lead.last_name.replace(/,/g, "");
            self.rs.lead.second_last_name = self.rs.lead.second_last_name.replace(/,/g, "");
            self.rs.lead.status = 'COMPLETE';
            self.rs.lead.monthly_salary_selected = self.rs.lead.monthly_salary;
            self.rs.lead.accept_terms = true;
            // update shortLead to fullLead
            self.wizardService.updateLead(self.rs.lead)
            .then(function(data) {
                self.wizardService.getSetting("name=KEY_TOPTARJETAS")
                .then(function(keyData){
                    if(keyData.data[0].value === "true" && ms === 'más_de__7_000_y_menos_de__15_000' && self.rs.withParams){
                        self.rs.lead.monthly_salary = 15000
                    }
                    if(keyData.data[0].value === "true" && ms === 'más_de__4_000_y_menos_de__7_000' && self.rs.withParams){
                        self.rs.lead.monthly_salary = 7000
                    }
                    self.wizardService.leadApply(self.rs.lead)
                    .then(function(resp) {
                        if (resp.status == "OK") {
                            const lenderName = resp.details.lender.name;   
                            self.wizardService.getProductXSell(lenderName.toLowerCase())
                            .then(function(products){
                                self.window.dataLayer.push({
                                    'event': 'apply',
                                    'lender': lenderName.toLowerCase()
                                });
                                if(lenderName == 'CORU'){
                                    self.window.dataLayer.push({
                                        'event': 'api_result',
                                        'lender': lenderName
                                    });
                                }else{
                                    self.window.dataLayer.push({
                                        'event': 'xsell',
                                        'lender': lenderName
                                    });
                                }
                                
                                self.window.fbq('track', 'CompleteRegistration');
                                self.cookies.put('apply', JSON.stringify({ products: products, lender: lenderName.toLowerCase()}), { secure: false, samesite: 'strict'});
                                self.state.go('result', { products: products, lender: lenderName.toLowerCase()});
                            })
                        }
                    })
                })
            });
        }
    }
};