angular
    .module('app')
    .component('additionalInfoComponent', {
        templateUrl: 'app/components/additionalInfo/additionalInfo.component.html',
        controller: AdditionalInfo
    });

/** @ngInject */
function AdditionalInfo($rootScope, $stateParams, wizardService, $state, $localStorage, $cookies) {
    this.rs = $rootScope;
    this.sp = $stateParams;
    this.wizardService = wizardService;
    this.state = $state;
    this.localStorage = $localStorage;
    this.cookies = $cookies;

    if (!this.sp.product) {
        this.state.go('app');
    }

    var campaigns_params = JSON.parse(this.cookies.get('campaign_params'));

    this.pixelClick = "https://ojo7.ltroute.com/click.track?CID=420723&AFID=" + campaigns_params.utm_source
        + "&ADID=1950256&SID=" + campaigns_params.SID
        + "&AffiliateReferenceID=" + campaigns_params.sub_id;
}

AdditionalInfo.prototype = {

    completeLead: function () {
        var self = this;
        self.showSpinner = true;
        self.wizardService.updateToFullLead(self.rs.lead)
            .then(function (data) {
                self.rs.lead = data;
                self.showSpinner = false;
                self.state.go('thankyou', {
                    full_app: true,
                    lead_id: self.rs.lead.id,
                    lead_email: self.rs.lead.email
                });
            });
    }

}